import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from 'const/router'
import logo from 'assets/images/logo.png'
import { ReactComponent as Logout } from 'assets/images/logout_icon.svg'
import ReactTooltip from 'react-tooltip'
import { setLocalStorageDetails, useMyProfile } from 'hooks/common'
import { IService } from './Services.types'
import {
	calculateServiceColumnLength,
	getServiceDetail,
	usePermissionDetails,
} from './Services.hooks'
import { EVENT_NAME, LOCAL_STORAGE } from 'const/common'
import { ServiceComponent } from './Services.component'
import { useUrlQuery } from 'hooks/url'
import ServicTableFilter from './Service.TableFilter'
import Loader from 'components/Loader'
import { ReactComponent as DefaultProfileIcon } from 'assets/images/ic_avatar_default.svg'
import { onStringify, signOut } from 'utils/common'
import { handleStorageChange } from 'utils/appCommon'
import { onInsertChildren } from 'router/router.copy.config'
import TamLogo from 'assets/images/TAM_LOGO.png'
import { errorNotify } from 'utils/toster'
import { createAudit } from 'services/requests'
import { ACTIONS, AUDIT_GROUPS, AUDIT_STATUS } from 'types/entities'
import NoPermissionBox from 'components/NoPermissionBox'

function Services() {
	/**
	 * *   hooks Section
	 */
	const navigate = useNavigate()
	const profile: any = useMyProfile()

	const [params] = useUrlQuery<any>()
	/**
	 * *   Variable Section
	 */

	const tamENV = window.location.origin === process.env.REACT_APP_TAM_BRAND_URL
	// const isEpilogist = profile?.role === ROLES.EPIDEMIOLOGIST
	/**
	 * *   State Section
	 */
	const [serviceDetail, setServiceDetail] = useState<any>([])
	const [routesDetails, setroutesDetails] = useState<any>([])
	const [routeData, setRouteData] = useState([])

	const onPermissionUpdate = () => {
		const routeDetails = handleStorageChange()

		setroutesDetails(routeDetails)
	}

	/**
	 * *   Custom hooks Section
	 */
	const permissionDetail: any = usePermissionDetails({
		client: params?.client ?? undefined,
		// role: params?.role ?? undefined,
		providerRole: params?.providerRole ?? undefined,
		isEnabled: true,
	})

	const isPermissionDetailFetching = permissionDetail?.isFetching
	/**
	 * *   Function Section
	 */
	const onServiceClick = (serviceObj: IService) => {
		const findProviderRole = profile?.providerRole?.find(
			(item: any) => item?.client?.id === params?.client,
		)
		if (serviceObj?.isActive) {
			// const { serviceName } = serviceObj
			// setLocalStorageDetails(LOCAL_STORAGE?.SERVICE_NAME, serviceName)
			if (findProviderRole?.roles?.defaultRoute?.length) {
				const defaultRouteNavigateRoute = findProviderRole?.roles?.defaultRoute?.find(
					(findDefault: any) => findDefault?.providerService?.id === serviceObj?.serviceId,
				)
				navigate(`${defaultRouteNavigateRoute?.routeName}`)
			} else {
				const permissionData = permissionDetail?.data?.data
				const { serviceName } = serviceObj
				const servicePermissionDetails =
					Array.isArray(permissionData) &&
					permissionData?.find(
						(item: any) => item?.clientService?.clientServiceName === serviceName,
					)
				const commonDefaultRoute =
					servicePermissionDetails?.providerServiceModules?.[0]?.providerModule?.clientModules?.slug

				navigate(`/${commonDefaultRoute}`)
			}
		}
	}
	const updatePermissionDetailnServiceClick = (serviceObj: IService) => {
		const customEvent = new CustomEvent(EVENT_NAME?.UPDATE_ROUTES, {
			detail: routeData,
		})
		const handlePermissionUpdated = () => {
			window.removeEventListener(EVENT_NAME?.UPDATE_ROUTES, handlePermissionUpdated)
			onServiceClick(serviceObj)
		}
		window.addEventListener(EVENT_NAME?.UPDATE_ROUTES, handlePermissionUpdated)
		window.dispatchEvent(customEvent)
	}
	const onSetAppServiceRoutes = (serviceObj: IService) => {
		if (serviceObj?.isActive) {
			const { serviceName } = serviceObj
			setLocalStorageDetails(LOCAL_STORAGE?.SERVICE_NAME, serviceName, () => {
				updatePermissionDetailnServiceClick(serviceObj)
			})
		}
	}

	const updateRouteData = () => {
		if (routesDetails && routesDetails?.length > 0) {
			const createdRoutes: any = onInsertChildren(routesDetails)
			setRouteData(createdRoutes)
		}
	}

	const onSetServiceDetails = (details: any) => {
		const serviceData: any = Array.isArray(details) && details?.map(getServiceDetail)
		if (serviceData) {
			setServiceDetail(serviceData)
		}
	}

	/**
	 * *useEffects Section
	 * @param useEffectName
	 */

	useEffect(() => {
		updateRouteData()
	}, [routesDetails])

	useEffect(() => {
		if (permissionDetail?.data?.data) {
			createAudit({
				action: ACTIONS.LOGIN,
				status: AUDIT_STATUS.SUCCESS,
				credentials: profile?.email?.toLowerCase(),
				auditGroup: AUDIT_GROUPS.LOGIN,
			})
			onSetServiceDetails(permissionDetail?.data?.data)

			const permissionStringify: string = onStringify(permissionDetail?.data?.data)
			// Set data in local storage and call onPermissionUpdate after setting data
			setLocalStorageDetails(LOCAL_STORAGE?.PERMISSION_DETAIL, permissionStringify, () => {
				onPermissionUpdate()
			})
		} else if (permissionDetail?.isError) {
			errorNotify(`${permissionDetail?.error?.response?.data?.message}`)
			setServiceDetail([])
		} else {
			setServiceDetail([])
		}
	}, [isPermissionDetailFetching])

	const serviceColumnLength = calculateServiceColumnLength(serviceDetail && serviceDetail?.length)
	const serviceClassName = `grid grid-cols-${serviceColumnLength} gap-[23px] justify-center"`
	const isToShowDefaultProfile = !profile?.image
	/**
	 * *JSX Section
	 * @returns Services
	 */

	return (
		<div className={`bg-snow h-[100dvh] w-[100dvw] flex flex-col overflow-auto dashboard-bg`}>
			<div className="flex justify-between items-center w-full fixed top-0 p-3">
				<img src={tamENV ? TamLogo : logo} alt="logo" className="h-[77px] w-[138px]" />
				<ServicTableFilter setServiceDetail={setServiceDetail} />
				<div className="ServicTableFilter flex items-center gap-2">
					<div className="flex items-center mb-1">
						{isToShowDefaultProfile ? (
							<DefaultProfileIcon />
						) : (
							<img
								height={35}
								width={40}
								className="rounded-full w-[50px] h-[50px]"
								src={profile?.image}
								alt="profile"
							/>
						)}
						<label className="ml-2 text-dimGray flex whitespace-nowrap flex-nowrap cursor-pointer">{`${
							profile?.humanName?.given || 'First'
						} ${profile?.humanName?.family || 'Last'}`}</label>
					</div>
					<div
						onClick={async () => {
							await signOut()
							navigate(APP_ROUTES.SIGN_IN)
							ReactTooltip.hide()
						}}
						data-tip="Logout"
						className="mb-1 border border-fountainBlue rounded p-1 bg-fountainBlue cursor-pointer"
					>
						<Logout className="stroke-white" />
					</div>
				</div>
			</div>
			{!permissionDetail?.data?.data?.length && !isPermissionDetailFetching && params?.client && (
				<NoPermissionBox />
			)}
			<div className="flex md:flex-row justify-center  flex-col w-full px-4 md:px-0 gap-2.5 mt-48">
				{isPermissionDetailFetching ? (
					<Loader />
				) : (
					<div className={serviceClassName}>
						{serviceDetail?.length > 0
							? serviceDetail?.map((serviceObj: IService, index: number) => {
									return (
										<ServiceComponent
											indexKey={`${index}`}
											serviceObj={serviceObj}
											onServiceClick={onSetAppServiceRoutes}
										/>
									)
							  })
							: ''}
					</div>
				)}
			</div>
		</div>
	)
}

export default Services

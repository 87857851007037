import { useActivityLogger } from 'components/ActivityLogger'
import Button from 'components/Button'
import { commonMessages } from 'utils/messages'
import { ReactComponent as ErrorFound } from 'assets/images/PageNotWorking.svg'

export function ErrorFallback({
	error,
	resetErrorBoundary,
}: {
	error: Error
	resetErrorBoundary: () => void
}) {
	useActivityLogger(error as Error)
	return (
		<div role="alert" className="flex flex-col items-center justify-center h-full flex-1 gap-4">
			<p>{`${commonMessages.errorHandlingMessage}:`}</p>
			<pre>
				{' '}
				<ErrorFound />{' '}
			</pre>
			<Button onClick={resetErrorBoundary}>Try again</Button>
		</div>
	)
}

import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Column } from 'react-table'
import { UseMutationOptions, UseQueryOptions, useQuery, useMutation } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import axiosLibrary from 'axios'
import ToggleSwitch from 'components/Switch'
import { ReactComponent as EditIcon } from 'assets/images/ic_edit.svg'
import { ReactComponent as KeyIcon } from 'assets/images/key_icon.svg'
import { useUrlQuery } from 'hooks/url'
import {
	getClientDetails,
	useCheckScreenPermission,
	usePermission,
	useSortHandler,
} from 'hooks/common'
import { APP_ROUTES } from 'const/router'
import { ENDPOINTS } from 'const/endpoints'
import { CONTACT_POINT, IAxiosResponseWithPagination } from 'types/entities'
import { IFullProvider, IProvidersParams } from './Provider.interfaces'
import { ProviderContactColumns } from './Providers.constants'
import {
	getPartnerListByClientInProvider,
	getProviders,
	getProvidersForSchedules,
	statusProviderToggle,
} from './Provider.service'
import { IPartner } from 'modules/Partners/Partner.interfaces'
import { MODULE_NAME } from 'const/module'
import { COLUMN_NAME, SCREEN_NAME } from 'const/screen'
import { useProviders } from './Providers.hooks copy'

export function useProvidersTableData({
	partnerFilter,
	onEdit,
}: {
	partnerFilter: any
	onEdit: any
}): [
	Column<{ [key: string]: React.ReactNode }>[],
	{ [key: string]: React.ReactNode }[],
	(param: string) => void,
	Partial<IFullProvider> | null,
	React.Dispatch<React.SetStateAction<Partial<IFullProvider> | null>>,
	any,
] {
	/**
	 * *Variable and hooks section
	 */
	const [params, setParams] = useUrlQuery<IProvidersParams>()
	const stringfyPartner = JSON.stringify(partnerFilter)
	const clients = useProviders({ ...params, client: getClientDetails()?.clientId }, stringfyPartner)
	const onSort = useSortHandler(params.sort)

	/**
	 * *State section
	 */
	const [isChecked] = useState<any>()
	const [showChangePasswordModal, setChangePasswordModal] = useState<Partial<IFullProvider> | null>(
		null,
	)
	/**
	 * *Custom hooks section
	 */

	// Module wise permission custom hook to get permission details module wise
	const providerPermission = usePermission(MODULE_NAME?.PROVIDERS)
	//  Custom hook to check is screen Available
	const isProviderProfileScreenVisible = useCheckScreenPermission(SCREEN_NAME?.PROVIDER_PROFILE)
	// To filter action particular column if nothing to display in it
	const filterPartnersColumns = ProviderContactColumns?.filter((columnObj: any) => {
		return !(columnObj?.name === COLUMN_NAME?.ACTION && !providerPermission?.edit)
	})
	// Provider Profile
	const { mutateAsync: status } = useProviderStatusUpdateToggle({ active: isChecked })
	/**
	 * *useMemo section
	 */
	const clientsData = useMemo(() => {
		return (
			clients?.data?.data?.items.map((item: any, index: any) => {
				let contact = ''

				const phone = item?.providerContactPoints?.find(
					(item: any) => item?.contactPoint?.type === CONTACT_POINT.PHONE,
				)?.contactPoint?.text

				if (phone) {
					contact = phone
				}

				const speciality = item?.providerSpeciality?.map((item: any) => {
					return `${item?.speciality?.speciality} `
				})
				const formattedSpeciality = speciality.join(', ')

				return {
					col1: (
						<>
							{isProviderProfileScreenVisible ? (
								<Link
									key={index}
									to={APP_ROUTES.PROVIDER_PROFILE + `?id=${item?.id}`}
									className="text-fountainBlue"
								>
									<span>
										{item?.humanName?.text?.charAt(0).toUpperCase() +
											item?.humanName?.text?.slice(1) || '--'}
									</span>
								</Link>
							) : (
								<span>
									{item?.humanName?.text?.charAt(0).toUpperCase() +
										item?.humanName?.text?.slice(1) || '--'}
								</span>
							)}
						</>
					),
					col2: item?.client?.title || '--',
					col3: item?.partnerPointOfContact?.[0]?.partner?.companyName || '--',
					col4: (
						<div key={index} className="">
							{contact.replace(
								/^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/,
								function formatPhoneNumber(
									match: string,
									plus: string,
									p1: string,
									p2: string,
									p3: string,
								) {
									return `${plus ? '+1' : ''} (${p1}) ${p2}-${p3}`
								},
							) ?? '--'}
						</div>
					),
					col5:
						(
							<a href={`mailto:${item?.email || '--'}`} className="text-fountainBlue">
								{item?.email || '--'}
							</a>
						) || '--',
					col6: (
						<div className="w-[200px] whitespace-nowrap text-ellipsis overflow-hidden">
							<span data-tip={formattedSpeciality}>{formattedSpeciality || '--'}</span>
						</div>
					),
					col7: (
						<ToggleSwitch
							checked={item?.active}
							disabled={!providerPermission?.edit}
							id={item?.id}
							statusToggle={status}
							key={index}
							url={[ENDPOINTS.PROVIDER_STATUS]}
						/>
					),
					col8: (
						<div className="flex items-center gap-2">
							{providerPermission?.edit && (
								<span
									data-tip="Click to edit provider"
									onClick={() => {
										onEdit('edit')
										setParams((old) => ({
											...old,
											id: item?.id,
										}))
									}}
								>
									<EditIcon className="hover:stroke-fountainBlue  stroke-solidGray fill-solidGray hover:fill-fountainBlue" />
								</span>
							)}
							{providerPermission?.edit && (
								<span data-tip="Click to reset password">
									<KeyIcon
										className={
											'w-[26px] h-[24px] hover:stroke-fountainBlue stroke-solidGray  cursor-pointer'
										}
										onClick={() => {
											setChangePasswordModal(item)
										}}
										strokeWidth={1}
									/>
								</span>
							)}
						</div>
					),
				}
			}, []) || []
		)
	}, [clients?.data?.data?.items])

	return [
		filterPartnersColumns,
		clientsData,
		onSort,
		showChangePasswordModal,
		setChangePasswordModal,
		clients,
	]
}
/**
 *  Using the React Hooks useQuery is used to show Listing in Table.
 */
interface IProvidersQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<IFullProvider>>>,
		Partial<IProvidersParams> {}
export function useProvidersForSchedules({
	page,
	limit,
	sort,
	name,
	role,
	search,
	status,
	client,
	id,
	partner,
	...rest
}: IProvidersQueryProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<IFullProvider>>,
		any,
		IAxiosResponseWithPagination<Partial<IFullProvider>>
	>(
		[ENDPOINTS.PROVIDER_LIST, page, limit, sort, role, name, search, status, client, id, partner],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getProvidersForSchedules(
				id,
				{ page, limit, sort, name, status, role, search, client, partner },
				source,
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: false,
			...rest,
		},
	)
}
/**
 *  Using the React Hooks useMutation is used to toggle the status of a provider identified by its id
 */
interface IUpdateStatusToggle {
	active?: true | false
	clientId?: string
}
interface IUpdateStatusToggleProps
	extends Partial<UseMutationOptions<AxiosResponse<IPartner>, any, IUpdateStatusToggle>>,
		IUpdateStatusToggle {}

export function useProviderStatusUpdateToggle({
	active,
	clientId,
	...rest
}: IUpdateStatusToggleProps = {}) {
	//@ts-ignore
	const CancelToken = axiosLibrary.CancelToken
	const source = CancelToken.source()
	return useMutation<AxiosResponse<IPartner>, any, any>(
		async (id: string) => await statusProviderToggle(id, clientId, { active }, source),
		{
			...rest,
		},
	)
}

//  new created
interface IGetPartnerListingByClientQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<IFullProvider>>>,
		Partial<IProvidersParams> {}
export function useGetPartnerListByClientInProvider({
	id,
	limit,
	page,
	search,
	...rest
}: IGetPartnerListingByClientQueryProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<IFullProvider>>,
		any,
		IAxiosResponseWithPagination<Partial<IFullProvider>>
	>(
		[ENDPOINTS.PARTNER_LIST_BY_CLIENT, id, limit, page, search],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getPartnerListByClientInProvider(id, { limit, page, search }, source)
		},
		{
			suspense: false,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			...rest,
			enabled: !!id,
		},
	)
}

import { lazy } from 'react'

// const PrivateLayout = lazy(() => import('../layouts/PrivateLayout'))
// const HealthGoals = lazy(() => import('../modules/Profile/HealthGoals'))
const PartnerProfileLayout = lazy(() => import('../layouts/PartnerProfileLayout'))
const Chats = lazy(() => import('../modules/Chats/Chat.Component copy'))
const PartnerLocations = lazy(() => import('../modules/Partners/Partner/Locations'))
const PartnerPointOfContacts = lazy(() => import('../modules/Partners/Partner/PointOfContacts'))
const SchedulesComponent = lazy(() => import('../modules/Partners/Partner/Schedules'))
const Calendar = lazy(() => import('../modules/Calender'))
const MemberCalendar = lazy(() => import('../modules/Members/Member/MemberCalendar'))
const ProviderProfileLayout = lazy(() => import('../layouts/ProviderProfileLayout'))
const ProgramLayout = lazy(() => import('../layouts/ProgramLayout'))
const PROGRAMMANAGERDASHBOARD = lazy(() => import('../modules/ProgramManagerDashboard'))
const Referrals = lazy(() => import('../modules/Referrals'))
const MemberLayout = lazy(() => import('../layouts/MemberLayout'))
const Members = lazy(() => import('../modules/Members'))
const MemberPrograms = lazy(() => import('../modules/Members/Member/MemberPrograms'))
const MemberGoals = lazy(() => import('../modules/Members/Member/MemberGoals'))
const ReferralView = lazy(() => import('../modules/Referrals/ReferralsView/ReferralView'))

const EpidemiologistDashboard = lazy(
	() => import('../modules/Epidemiologist/EpidemiologistDashboard'),
)
const AdminDashboard = lazy(() => import('../modules/AdminDashboard'))
const EPIDEMIOLOGISTMEMBERS = lazy(() => import('../modules/Epidemiologist/EpidemiologistMembers'))
const EPIDEMIOLOGISTPARTNERS = lazy(
	() => import('../modules/Epidemiologist/EpidemiologistPartners'),
)
const Report = lazy(() => import('../modules/Epidemiologist/EpidemiologistReport'))
const MemberMdeView = lazy(
	() => import('../modules/Epidemiologist/EpidemiologistMembers/MemberMdeView'),
)
const ClaimNowProfileLayout = lazy(() => import('../layouts/ClaimNowLayout'))
const MemberAlerts = lazy(() => import('../modules/Members/Member/MemberAlerts'))
const NonHealthCareMemberDevices = lazy(
	() => import('../modules/NonHealthCareMembers/NonHealthCareMember/MemberDevices'),
)
const NonHealthCareMemberVitals = lazy(
	() => import('../modules/NonHealthCareMembers/NonHealthCareMember/MemberVitals'),
)

const FeeSchedules = lazy(() => import('../modules/FeeSchedules'))
const Program = lazy(() => import('../modules/Programs/Program'))
const Client = lazy(() => import('../modules/Clients/Client'))
const ClaimNow = lazy(() => import('../modules/Programs/Program/ClaimNow'))
const ProgramSessions = lazy(() => import('../modules/Programs/Program/Sessions'))
const ProgramGoals = lazy(() => import('../modules/Programs/Program/Goals'))
const ProgramVitalsDevies = lazy(() => import('modules/Programs/Program/VitalsDevices'))
const ProgramMembers = lazy(() => import('../modules/Programs/Program/Members'))
const MemberInformation = lazy(() => import('../modules/Members/Member/MemberInformation'))
// const Assessment = lazy(
// 	() => import('../modules/Members/Member/MemberAssessment/MemberAssessment.container'),
// )
const MemberVitals = lazy(() => import('../modules/Members/Member/MemberVitals'))
const MemberDevices = lazy(() => import('../modules/Members/Member/MemberDevices'))
const MemberConditions = lazy(() => import('../modules/Members/Member/MemberConditions'))
const MemberProfile = lazy(() => import('../modules/Members/Member/MemberProfile'))
const MemberJournalNotes = lazy(() => import('../modules/Members/Member/MemberJournalNotes'))
const SummaryReport = lazy(() => import('../modules/SummaryReport'))
const MemberTasks = lazy(() => import('../modules/Members/Member/MemberTasks'))
const ProviderDashboard = lazy(() => import('../modules/Providers/ProviderDashboard'))
const MyProfile = lazy(() => import('../modules/MyProfile'))
const FileUpload = lazy(() => import('../modules/Members/Member/MemberFileUploads'))
//end

const Audits = lazy(() => import('../modules/Audits'))
const Clients = lazy(() => import('../modules/Clients'))
const Providers = lazy(() => import('../modules/Providers'))
const VitalsComponent = lazy(() => import('../modules/Vitals'))
const MemberSessions = lazy(() => import('../modules/Members/Member/MemberSessions'))
const SessionInformation = lazy(
	() => import('../modules/Members/Member/MemberSessions/PatientSession'),
)
const Notification = lazy(() => import('../modules/Notification'))
const Programs = lazy(() => import('../modules/Programs'))
const Partners = lazy(() => import('../modules/Partners'))

const SettingsLayout = lazy(() => import('layouts/SettingLayout//SettingsLayout'))
const GlobalCodesCategory = lazy(
	() => import('modules/Settings/GlobalCodesCategorys/GlobalCodeCategorys.component'),
)
const GlobalCodes = lazy(() => import('modules/Settings/GlobalCodes'))
const GlobalSpecialities = lazy(() => import('modules/Settings/GlobalSpecialities'))
const GlobalConditions = lazy(
	() => import('modules/Settings/GlobalConditons/GlobalConditions.component'),
)
const Modules = lazy(() => import('../modules/Settings/Modules'))
const GlobalPrograms = lazy(() => import('../modules/Settings/GlobalPrograms'))
const GlobalSessions = lazy(() => import('../modules/Settings/GlobalSessions'))
// Session

const Roles = lazy(() => import('modules/Settings/Roles'))
const RoleLayout = lazy(() => import('layouts/RoleLayout'))
const Groups = lazy(() => import('modules/Groups'))
//Super-Admin Settings import section ends
const QuestionnnaireView = lazy(
	() => import('../modules/Members/Member/MemberSessions/SessionQuestionnaire'),
)
const Messages = lazy(() => import('../modules/Messages'))
const QuestionnaireLibrary = lazy(() => import('../modules/QuestionnairesLibrary'))
const Questionnaires = lazy(() => import('../modules/Questionnaires'))
const Templates = lazy(() => import('../modules/Templates'))
const TemplateView = lazy(() => import('../modules/Templates/Templates.Information'))
const Sessions = lazy(() => import('../modules/Sessions'))
const MemberScreenings = lazy(() => import('../modules/Members/Member/MemberScreenings'))
const TemplateClientView = lazy(() => import('../modules/Templates/TemplateClientMember'))
const NewAssessment = lazy(() => import('../modules/Members/Member/NewMemberAssessment'))
// import DashboardTaskManagement from 'modules/TaskManagementDashboard/TaskManagementDashboard'
const DashboardTaskManagement = lazy(
	() => import('modules/TaskManagementDashboard/TaskManagementDashboard'),
)
const TaskListing = lazy(
	() => import('../modules/TaskManagement/TaskListing/TaskListing.container'),
)
const TaskFollowUpsDetail = lazy(
	() => import('../modules/TaskManagement/FollowUpsListing/FollowUpsListing.component'),
)

// SMBP Modules

const ProgramManagerMembers = lazy(() => import('../modules/PmMembers'))
const SMBPDASHBOARD = lazy(() => import('../modules/NonHealthCareSmbpDashboard'))
const NonHealthCareMembers = lazy(() => import('../modules/NonHealthCareMembers'))
const NonHealthCareReports = lazy(
	() => import('../modules/NonHealthCareMembers/NonHealthCareReports'),
)
const NonHealthCareMemberLayout = lazy(() => import('../layouts/NonHealthCareMemberLayout'))

// TaskManagementTaskListing

const ManagementTaskListing = lazy(() => import('../modules/ManagementTaskListing'))
const TaskTaskListing = lazy(() => import('../modules/TaskTaskListing'))
// const TaskDetail = lazy(() => import('../modules/TaskManagement/'))
const TaskRecordTrack = lazy(() => import('../modules/TaskManagement/TaskRecord/TaskRecordTrack'))
const TaskDashboardRecordTrack = lazy(
	() => import('../modules/TaskManagement/TaskRecord/TaskDashboardRecordTrack'),
)
const TaskReportRecordTrack = lazy(
	() => import('../modules/TaskManagement/TaskRecord/TaskReportRecordTrack'),
)
const CallListing = lazy(
	() => import('../modules/TaskManagementDashboard/CallDetails/CallListing/CallListing.component'),
)
const CallDetailsComponent = lazy(
	() => import('modules/TaskManagementDashboard/CallDetails/CallDetails.component'),
)
const TaskManagement = lazy(() => import('../modules/TaskManagement'))
const TaskReport = lazy(() => import('../modules/TaskReport'))
const CalenderSchedule = lazy(() => import('../modules/Calender/CalenderSchedule'))
// const CalendarSchedulesComponent = lazy(() => import('../modules/Partners/Partner/Schedules'))
const PartnerScheduleComponent = lazy(
	() => import('../modules/Partners/Partner/Schedules/PartnerSchedule'),
)
const TaskFollowUp = lazy(() => import('modules/TaskManagement/TaskFollowUps'))
const TaskLibrary = lazy(() => import('modules/TaskLibrary'))
const DashboardTaskFollowUp = lazy(() => import('modules/TaskManagement/DashbordTaskFollowUps'))

const DasboardTaskManagementDetail = lazy(
	() => import('modules/TaskManagement/TaskDetail/DashboardTaskManagementDetail'),
)
const TaskManagementDetail = lazy(
	() => import('modules/TaskManagement/TaskDetail/TaskManagementDetail'),
)
const TaskReportTaskDetail = lazy(() => import('modules/TaskReport/TaskReportTaskDetail'))

export const componentDetailsObj: any = {
	ClaimNowProfileLayout: <ClaimNowProfileLayout />,
	ProviderProfileLayout: <ProviderProfileLayout />,
	// SchedulesComponent: <SchedulesComponent />, // earlier used
	SchedulesComponent: <PartnerScheduleComponent />, // new component used
	PartnerPointOfContacts: <PartnerPointOfContacts />,
	PartnerLocations: <PartnerLocations />,
	PartnerProfileLayout: <PartnerProfileLayout />,
	ProgramLayout: <ProgramLayout />,
	ProgramMembers: <ProgramMembers />,
	ProgramVitalsDevies: <ProgramVitalsDevies />,
	ProgramGoals: <ProgramGoals />,
	ProgramSessions: <ProgramSessions />,
	Notifications: <Notification />,
	Calendar: <Calendar />,
	VitalsComponent: <VitalsComponent />,
	MyProfile: <MyProfile />,
	Chats: <Chats />,
	SummaryReport: <SummaryReport />,
	AdminDashboard: <AdminDashboard />,
	Epidemiologistpartners: <EPIDEMIOLOGISTPARTNERS />,
	// Epodemiologistpartners: <EPIDEMIOLOGISTPARTNERS />, // need to remove
	MemberMdeView: <MemberMdeView />,
	Report: <Report />,
	Epidemiologistmembers: <EPIDEMIOLOGISTMEMBERS />,
	EpidemiologistDashboard: <EpidemiologistDashboard />,
	ProviderDashboard: <ProviderDashboard />,
	ProgramManagerDashboard: <PROGRAMMANAGERDASHBOARD />,
	Audits: <Audits />,
	Clients: <Clients />,
	Client: <Client />,
	ClaimNow: <ClaimNow />,
	Providers: <Providers />,
	Programs: <Programs />,
	Program: <Program />,
	FeeSchedules: <FeeSchedules />,
	Members: <Members />,
	MemberLayout: <MemberLayout />,
	MemberInformation: <MemberInformation />,
	MemberVitals: <MemberVitals />,
	MemberAlerts: <MemberAlerts />,
	MemberPrograms: <MemberPrograms />,
	MemberDevices: <MemberDevices />,
	MemberGoals: <MemberGoals />,
	MemberSessions: <MemberSessions />,
	MemberConditions: <MemberConditions />,
	MemberJournalNotes: <MemberJournalNotes />,
	SessionInformation: <SessionInformation />,
	QuestionnaireView: <QuestionnnaireView />,
	MemberTasks: <MemberTasks />,
	MemberProfile: <MemberProfile />,
	Assessment: <NewAssessment />,
	Partners: <Partners />,
	Referrals: <Referrals />,
	ReferralView: <ReferralView />,
	SettingsLayout: <SettingsLayout />,
	GlobalCodesCategory: <GlobalCodesCategory />,
	GlobalCodes: <GlobalCodes />,
	GlobalSpecialities: <GlobalSpecialities />,
	GlobalConditions: <GlobalConditions />,
	Modules: <Modules />,
	GlobalPrograms: <GlobalPrograms />,
	GlobalSessions: <GlobalSessions />,
	Roles: <Roles />,
	RoleLayout: <RoleLayout />,
	Groups: <Groups />,
	Messages: <Messages />,
	QuestionnaireLibrary: <QuestionnaireLibrary />,
	Screening: <Questionnaires />,
	Templates: <Templates />,
	TemplateView: <TemplateView />,
	Sessions: <Sessions />,
	FileUpload: <FileUpload />,
	MemberScreening: <MemberScreenings />,
	ScreeningTemplateDashboard: <TemplateClientView />,
	GlobalProgramLayout: <ProgramLayout />,
	GlobalProgramGoals: <ProgramGoals />,
	GlobalProgramVitalsDevies: <ProgramVitalsDevies />,
	GlobalProgramSessions: <ProgramSessions />,
	//  component

	TaskManagement: <TaskManagement />,
	DashboardTaskManagement: <DashboardTaskManagement />,
	// TaskFollowUpsDetail: <TaskFollowUpsDetail />, //old //follow up task management
	TaskFollowUpsDetail: <TaskFollowUp />, // New follow up task management
	DashboardTaskFollowUpsDetail: <DashboardTaskFollowUp />, // dashboard follow up task management
	// TaskDetail: <TaskDetail />, // used for both task detail and dashboard task detail
	TaskDetail: <TaskManagementDetail />, // used for both task detail
	DasboardTaskDetail: <DasboardTaskManagementDetail />, // used for dashboard task detail
	TaskReportTaskDetail: <TaskReportTaskDetail />, // used for task report task details
	TaskRecordTrack: <TaskRecordTrack />,
	TaskDashboardRecordTrack: <TaskDashboardRecordTrack />,
	TaskReportRecordTrack: <TaskReportRecordTrack />,
	CallListing: <CallListing />,
	CallDetailsComponent: <CallDetailsComponent />,
	TaskReport: <TaskReport />,
	CalendarSchedulesComponent: <CalenderSchedule />,
	MemberCalendar: <MemberCalendar />,
	TaskTaskListing: <TaskTaskListing />,
	ManagementTaskListing: <ManagementTaskListing />,
	TaskListing: <TaskLibrary />,

	// SMBP Module
	ProgramManagerMembers: <ProgramManagerMembers />,
	SMBPDASHBOARD: <SMBPDASHBOARD />,
	NonHealthCareMembers: <NonHealthCareMembers />,
	NonHealthCareReports: <NonHealthCareReports />,
	NonHealthCareMemberLayout: <NonHealthCareMemberLayout />,
	NonHealthCareMemberVitals: <NonHealthCareMemberVitals />,
	NonHealthCareMemberDevices: <NonHealthCareMemberDevices />,
}

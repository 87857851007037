import { memo } from 'react'
import { useField } from 'formik'

import classnames from 'classnames'
import { IRadioButtonsFieldProps, RadioButtonsField } from 'components/RadioButtonsField'
import Label from 'components/Label'

interface IRadioButtonField extends Omit<IRadioButtonsFieldProps, 'group' | 'classNames'> {
	name: string
	label?: string
	inlineLabel?: string
	className?: string
	ref?: React.MutableRefObject<any>
	require?: boolean | string
	value?: any
	onChange?: any
	isSessionType?: boolean
	classNames?: {
		input?: string
		message?: string
		label?: string
		inlineLabel?: string
	} & IRadioButtonsFieldProps['classNames']
}

export function FormikRadioButtonField({
	name,
	className,
	label,
	inlineLabel,
	classNames,
	require,
	isSessionType,
	value,
	onChange = () => {
		{
		}
	},
	...rest
}: IRadioButtonField) {
	const [field, meta, helpers] = useField(name)
	const error = (!!meta.touched && meta.error) || ''
	return (
		<div className={classnames('flex flex-col w-full', className, { 'pb-2': !error })}>
			{label && <Label name={label} className={classNames?.label} required={require} />}
			<div className="flex flex-row flex-wrap sm:items-center w-max">
				{inlineLabel && (
					<Label
						name={inlineLabel}
						className={classnames(classNames?.inlineLabel, '!mb-0')}
						required={require}
					/>
				)}
				<RadioButtonsField
					{...field}
					{...rest}
					className={classnames(
						classNames?.input,
						'p-2 w-full  h-full text-sm flex-wrap sm:flex-row flex-col gap-2',
					)}
					group={name}
					value={value ?? field?.value}
					classNames={{ inputWrapper: classNames?.inputWrapper }}
					onChange={(data) => {
						helpers.setValue(data)
						onChange(data)
					}}
					isSessionType={isSessionType}
				/>
			</div>
			<div className={classnames('text-xs', classNames?.message)}>{error}</div>
		</div>
	)
}

export default memo(FormikRadioButtonField)

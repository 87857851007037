import { useEffect } from 'react'
import { getAllProviderClients, getPartnerCalendarEvents } from './Calendar.service'
import { IAxiosResponseWithPagination, IFullPatient } from 'types/entities'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { INonHeathCarePartners, IPartnerCalendar, getPatientsList } from 'services/requests'
import { ENDPOINTS } from 'const/endpoints'
import axios, { AxiosResponse } from 'axios'

/**
 * useNonHealthcarePartners hook is called with the options object and
 * setNonHealthcarePartners state setter function.
 * The hook takes care of updating the nonHealthcarePartners state whenever the options object changes.
 */
export const useNonHealthcarePartnersHook = (options: any, setNonHealthcarePartners: any) => {
	useEffect(() => {
		if (!options?.isFetching) {
			const items = options?.data?.data?.items?.map((item: any) => {
				return {
					...item,
					label: item?.companyName,
					value: item?.id,
				}
			})
			setNonHealthcarePartners(items as any)
		}
	}, [options?.data?.data?.items, options?.isFetching, setNonHealthcarePartners])
}

/**
 * usePatientListHook hook is called with the options object and
 * setPatientsList state setter function.
 * The hook takes care of updating the nonHealthcarePartners state whenever the options object changes.
 */

export const usePatientListHook = (options: any, setPatientsList: any) => {
	useEffect(() => {
		if (!options?.isFetching) {
			const items = options?.data?.data?.items
				?.filter((item: any) => item?.active)
				?.map((item: any) => {
					return {
						...item,
						label: item?.humanName?.text,
						value: item?.id,
					}
				})
			setPatientsList(items as any)
		}
	}, [options?.data?.data?.items, options?.isFetching, setPatientsList])
}

/**
 *
 * useSetTimeRangeParams hook is called with these parameters,
 * and the params state variable is updated with the startTime and
 * endTime properties of the timeRange object. The updated values are then displayed in the component.
 */
export function useSetTimeRangeParams(timeRange: any, setParams: any) {
	useEffect(() => {
		if (timeRange?.startTime && timeRange?.endTime) {
			setParams((prev: any) => ({
				...prev,
				startTime: timeRange?.startTime,
				endTime: timeRange?.endTime,
			}))
		}
	}, [timeRange, setParams])
}
// getAllProviderClients
interface ICalenderProviderListQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<INonHeathCarePartners>>>,
		Partial<INonHeathCarePartners> {}
export function useAllProviderClientList({
	page,
	limit,
	sort,
	search,
	status,
	client,
	...rest
}: ICalenderProviderListQueryProps) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<INonHeathCarePartners>>,
		any,
		IAxiosResponseWithPagination<Partial<INonHeathCarePartners>>
	>(
		[ENDPOINTS.PROVIDER_CLIENTS, page, limit, sort, status, search, client],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getAllProviderClients({ page, limit, sort, status, search, client }, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!client,
			...rest,
		},
	)
}

interface IPartnerCalendarProps
	extends UseQueryOptions<AxiosResponse<any>>,
		Partial<IPartnerCalendar> {}

export function usePartnerCalendar({
	id,
	clientId,
	startTime,
	endTime,
	patientId,
	providerId,
	...rest
}: IPartnerCalendarProps = {}) {
	return useQuery<any>(
		[ENDPOINTS.PARTNER_CALENDER, id, providerId, startTime, endTime, patientId, clientId],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getPartnerCalendarEvents(
				clientId,
				{ provider: providerId, startTime, endTime, patient: patientId },
				source,
			)
		},
		{
			refetchOnWindowFocus: false,
			keepPreviousData: true,
			enabled: !!startTime && !!endTime,
			suspense: false,
			cacheTime: 0,
			...rest,
		},
	)
}

interface IPatientsListProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<IFullPatient>>>, //response time
		Partial<INonHeathCarePartners> {} //request time

export function usePatientsList({
	id,
	page,
	limit,
	sort,
	name,
	search,
	status,
	provider,
	...rest
}: IPatientsListProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<IFullPatient>>,
		any,
		IAxiosResponseWithPagination<Partial<IFullPatient>>
	>(
		[ENDPOINTS.PATIENTS, page, limit, sort, name, search, status, id, provider],
		(): any => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getPatientsList({ page, limit, sort, name, status, search, provider }, source)
		},
		{
			keepPreviousData: false,
			refetchOnWindowFocus: false,
			...rest,
		},
	)
}

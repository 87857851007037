import { useEffect, useMemo, useRef, useState } from 'react'
import { AdvancedSelectField } from 'components/AdvancedSelectField'
import {
	setLocalStorageDetails,
	useMyProfile,
	useServicesClientsAllDropdownOptions,
} from 'hooks/common'
import { useUrlQuery } from 'hooks/url'
import { SelectOption } from 'types/entities'
import { DROPDOWN_LIMIT, LOCAL_STORAGE } from 'const/common'
import { onStringify } from 'utils/common'

let clientPage = 1
let totalPages = 0
let clientDataFetching = true

const ServicTableFilter = (props?: any) => {
	/**
	 * *   Custom hooks Section
	 */
	const alreadyMount: any = useRef(false)
	const profile: any = useMyProfile()
	const [params, setParams] = useUrlQuery<any>()
	/**
	 * *   Variables Section
	 */
	const isSuperAdminOptionsFind = profile?.providerRole?.some((role: any) => {
		return role?.roles?.isSuperAdmin
	})

	/**
	 * *   State Section
	 */
	const [clientDropdownProp, setClientDropdownProp] = useState(1)

	/**
	 * *   API Hooks Section
	 */
	const {
		options,
		isFetching,
		meta,
		makeSearch: clientSearch,
	} = useServicesClientsAllDropdownOptions({
		limit: DROPDOWN_LIMIT.LIMIT,
		page: clientDropdownProp,
		setPage: setClientDropdownProp,
		isEnabled: isSuperAdminOptionsFind,
	})
	useEffect(() => {
		if (meta?.meta?.totalPages) {
			totalPages = meta?.meta?.totalPages
		}
	}, [meta?.meta?.totalPages])
	const OnScrollDown = () => {
		if (totalPages > clientPage && !clientDataFetching) {
			clientPage = clientPage + 1
			setClientDropdownProp(clientPage)
		}
	}
	useEffect(() => {
		clientDataFetching = isFetching
	}, [isFetching])
	useEffect(() => {
		totalPages = 0
		clientPage = 1
		clientDataFetching = true
	}, [])
	/**
	 * *   Function Section
	 */

	/**
	 * *   This function create for Options shown in client dropdown in case when not logged in with Super Admin..
	 */
	const clientOptions = () => {
		const clientOptionsLabel = profile?.providerRole?.map((item: any) => {
			return {
				label: item?.client?.title,
				value: item?.client?.id,
				id: item?.client?.id,
				rolesId: item?.roles?.id,
				rolesTitle: item?.roles?.roleName,
				rolesSlug: item?.roles?.slug,
				providerRole: item?.id,
			}
		})
		return clientOptionsLabel
	}
	const clientDropdownOptions: any = isSuperAdminOptionsFind ? options : clientOptions()
	//  This function is used for on selection of client
	const onSelectClient = (selectedObj: any) => {
		const storeClientRoleAndProviderRole = onStringify({
			clientDetails: {
				clientLabel: selectedObj?.label ?? undefined,
				clientId: selectedObj?.id ?? undefined,
				carehalo: selectedObj?.careHalo ?? false,
			},
			roleDetails: {
				rolesSlug: isSuperAdminOptionsFind ? profile?.role : selectedObj?.rolesSlug,
				rolesId: selectedObj?.rolesId,
			},
			providerRoleDetails: {
				providerRole: selectedObj?.providerRole,
			},
		})
		setLocalStorageDetails(LOCAL_STORAGE?.INHERITED_LOGIN, storeClientRoleAndProviderRole)
		setParams((prev) => {
			return {
				...prev,
				client: selectedObj?.value ?? undefined,
				role: selectedObj?.roles ?? undefined,
				providerRole: selectedObj?.providerRole ?? undefined,
			}
		})
	}
	useEffect(() => {
		if (!params?.client) {
			props?.setServiceDetail([])
		}
	}, [params?.client])

	useEffect(() => {
		if (clientDropdownOptions?.length === 1 && !alreadyMount.current) {
			onSelectClient(clientDropdownOptions[0])
			alreadyMount.current = true
		}
	}, [clientDropdownOptions])

	/**
	 * *JSX Section
	 * @returns Service Table Filters
	 */
	return (
		<>
			<AdvancedSelectField
				isLoading={isFetching}
				isClearable
				label="Select Client"
				placeholder="Select Client"
				value={useMemo(() => {
					const findOptions = clientDropdownOptions?.find((item: any) => {
						return item?.value === params?.client
					})
					if (findOptions) {
						return new SelectOption(findOptions?.value, findOptions?.label)
					}
				}, [params?.client, clientDropdownOptions])}
				closeMenuOnSelect
				className="sm:w-[420px] select-with-shadow absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
				options={clientDropdownOptions}
				//@ts-ignore
				onChange={(value: any) => {
					onSelectClient(value)
				}}
				onCustomMenuScrollToBottom={OnScrollDown}
				onInputChange={clientSearch}
			/>
		</>
	)
}

export default ServicTableFilter

import classnames from 'classnames'
import { useUrlQuery } from 'hooks/url'
import { memo } from 'react'
import { ISelectOption } from 'types/forms'

export interface IRadioButtonsFieldProps
	extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
	id?: string
	options: Array<ISelectOption<boolean | string>> | any
	group: string
	box?: boolean
	classNames?: {
		inputWrapper?: string
	}
	isSessionType?: boolean
	type?: string
	setValue?: (value: any, shouldValidate?: boolean | undefined) => void
	value?: any
	onRadioChange?: any
	isDefault?: boolean
	showLabel?: boolean
}

function RadioButtonsField({
	id,
	options,
	className,
	value,
	classNames,
	isSessionType,
	box = true,
	isDefault = false,
	showLabel = true,
	...rest
}: IRadioButtonsFieldProps) {
	const classes = classnames(box && 'select', className)

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	return (
		<div className={classnames(classes, isDefault ? '' : 'flex')}>
			{options.map((item: any, index: any) => {
				const checked = isSessionType ? item.id === value : item.value === value
				return (
					<div
						className={classnames(
							'flex items-center ',
							classNames?.inputWrapper ?? 'mr-8 whitespace-nowrap',
						)}
						key={index}
					>
						<input
							style={{
								appearance: 'none',
								width: '18px',
								height: '18px',
							}}
							type="radio"
							id={rest.group + index + item}
							className={classnames(
								'mr-2 !border-[#62bdb5] text-transparent focus:text-transparent !focus:shadow-transparent !focus:outline-0 border border-solid relative cursor-pointer rounded-full flex justify-center items-center flex-col box-content flex-shrink-0',
								{
									'border-fountainBlue': checked,
									'!border-veryLightGray before:block  before:!bg-veryLightGray':
										rest.disabled && checked,
									'before:block before:bg-fountainBlue before:w-[10px] before:h-[10px] before:rounded-full before:absolute focus:text-transparent !focus:shadow-transparent focus:outline-0':
										checked,
									'!cursor-not-allowed bg-slate-100': rest.disabled,
								},
							)}
							checked={checked}
							{...rest}
							//@ts-ignore
							value={isSessionType ? item?.id : item.value}
							onChange={(e) => {
								//@ts-ignore
								rest?.onChange && rest?.onChange(e.target?.value)
								rest?.onRadioChange && rest?.onRadioChange(item)
							}}
						/>
						{showLabel && (
							<label htmlFor={rest.group + index} className="cursor-pointer">
								{item.label}
							</label>
						)}
					</div>
				)
			})}
		</div>
	)
}

RadioButtonsField.defaultProps = { options: [] }

export default memo(RadioButtonsField)

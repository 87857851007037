import { ISelectOption } from 'types/forms'
import { IFullRoleBase } from './Role.interface'
import { IClient } from 'modules/Clients/Clients.interface'
import { toCamelCase } from 'hooks/common'

export class RoleBasePayload {
	roleName: string | null = null
	client: ISelectOption | null = null
	isActive: boolean | undefined
	parentRole: ISelectOption | null = null
	slug: string | null = null

	constructor({ ...data }: Partial<RoleBaseInitialState> = {}) {
		this.addRoleName(data?.roleName)
		this.addClient(data?.client)
		this.addIsActive(data?.isActive)
		this.addParentRole(data?.parentRole)
		this.addSlug(data?.roleName)
	}

	addParentRole(parentRole: string | any | null = null) {
		if (parentRole) {
			this.parentRole = parentRole?.value
		}
	}

	addRoleName(roleName: string | null = null) {
		if (roleName) {
			this.roleName = roleName
		}
	}
	addSlug(roleName: string | null = null) {
		if (roleName) {
			const regex = /\//
			const regexofLastString = new RegExp(`^\\${roleName}`, 'i')

			const match = roleName?.match(regex)

			if (match || !!roleName?.match(regexofLastString)) {
				const splitRoleName = roleName.split('/')
				if (roleName?.startsWith('/')) {
					this.slug = toCamelCase(splitRoleName?.[1]?.toLowerCase())
				} else {
					this.slug = toCamelCase(splitRoleName?.[0]?.toLowerCase())
				}
			} else if (!match) {
				this.slug = toCamelCase(roleName)
			}
		}
	}
	addClient(client: string | any | null = null) {
		if (client) {
			this.client = client
		}
	}

	addIsActive(isActive?: 'isActive' | 'isInactive') {
		if (isActive === 'isActive') {
			this.isActive = true
		}
		if (isActive === 'isInactive') {
			this.isActive = false
		}
	}
}

export class RoleBaseInitialState {
	roleName: string | null = null
	client: ISelectOption | null = null
	parentRole: ISelectOption | null = null
	isActive: 'isActive' | 'isInactive' = 'isActive'

	constructor({ ...data }: Partial<IFullRoleBase> = {}) {
		this.addRoleName(data?.roleName)
		this.addClient(data?.client)
		this.addIsActive(data?.isActive)
		this.addParentRole(data?.parentRole)
	}

	addRoleName(roleName: string | null = null) {
		if (roleName) {
			this.roleName = roleName
		}
	}
	addParentRole(parentRole: any) {
		if (parentRole) {
			this.parentRole = {
				label: parentRole?.roleName,
				value: parentRole.id,
			}
		}
	}
	addClient(client: IClient | any | null = null) {
		if (client) {
			this.client = {
				label: client.title,
				value: client.id,
			}
		}
	}

	addIsActive(isActive?: boolean) {
		if (typeof isActive === 'boolean') {
			if (isActive) {
				this.isActive = 'isActive'
			}
			if (!isActive) {
				this.isActive = 'isInactive'
			}
		}
	}
}

import { CancelTokenSource } from 'axios'
import { INonHeathCarePartners, IPartnerCalendar } from 'services/requests'
import { ENDPOINTS } from 'const/endpoints'
import _ from 'lodash'
import { commonMessages } from 'utils/messages'
import { IPagination } from 'types/entities'
import axios from 'services/axios'

export function getPartnerCalendarEvents(
	clientId?: string | number,
	params?: Partial<IPartnerCalendar>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)
	delete filteredParams?.provider
	const promise = axios.get(
		params?.provider
			? ENDPOINTS.PARTNER_CALENDER + '/' + clientId + '/' + params?.provider
			: ENDPOINTS.PARTNER_CALENDER + '/' + clientId,

		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

// Get All Provider in behalf of clients
export function getAllProviderClients(
	params: Partial<INonHeathCarePartners>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<INonHeathCarePartners>>>(
		ENDPOINTS.PROVIDER_CLIENTS + '/' + params?.client,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

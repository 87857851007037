import _ from 'lodash'
import { CancelTokenSource } from 'axios'
import axios from 'services/axios'
import { ENDPOINTS } from 'const/endpoints'
import { commonMessages } from 'utils/messages'
import { IPagination } from 'types/entities'
import { PartnerPayload } from 'modules/Partners/Partner/Partner.types'
import { IFullProvider, IProvidersParams } from './Provider.interfaces'
import { ProviderPayload } from './Provider/Provider.types'
import { onCreateEndpointsWithClientid } from 'utils/common'

/**
 * change toggle of 2FA in profile
 */
export function statusToggle(
	id: string | undefined,
	clientId: string | undefined,
	data: Partial<ProviderPayload>,
	source?: CancelTokenSource,
) {
	const promise = axios.patch(ENDPOINTS.PARTNER_ENTITIES_STATUS + '/' + clientId + '/' + id, data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * send MULTI_FACTOR_AUTHENTICATION status
 */
export function send2faStatus(data: Partial<any>, source?: CancelTokenSource) {
	const promise = axios.post<any>(ENDPOINTS.MULTI_FACTOR_AUTHENTICATION, data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * Create Provider
 */
export function createProvider(
	clientId: string | undefined,
	data: Partial<ProviderPayload>,
	source?: CancelTokenSource,
) {
	if (!data.addSpeciality) {
		delete data.addSpeciality
	}
	if (!data?.npi) {
		delete data.npi
	}

	const promise = axios.post<ProviderPayload>(ENDPOINTS.PROVIDER_ENTITY + '/' + clientId, data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * Update Provider
 */

export function updateProvider(
	id: string | undefined,
	clientId: string | undefined,
	data: Partial<ProviderPayload>,
	source?: CancelTokenSource,
) {
	if (!data?.npi) {
		delete data.npi
	}
	const promise = axios.post<ProviderPayload>(
		ENDPOINTS.PROVIDER_ENTITY + '/' + clientId + '/' + id,
		data,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * Get Provider Listing
 */

export function getProviders(
	client: string | undefined,
	params: Partial<IProvidersParams>,
	source?: CancelTokenSource,
) {
	const partnerConvert = params.partner && JSON.parse(params?.partner)
	const partnerFilterParams = partnerConvert?.some((ds: any) => ds)
	if (!partnerFilterParams) {
		delete params?.partner
	}

	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<IFullProvider>>>(
		ENDPOINTS.PROVIDERS + '/' + client,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
/**
 * Get Provider For Schedules
 */

export function getProvidersForSchedules(
	client: string | undefined,
	params: Partial<IProvidersParams>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<IFullProvider>>>(
		onCreateEndpointsWithClientid(ENDPOINTS.PROVIDER_LIST),
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * Get Provider by id
 */

export function getProvider(
	id: string | undefined,
	clientId: string | undefined,
	source?: CancelTokenSource,
) {
	const promise = axios.get<IFullProvider>(ENDPOINTS.PROVIDERS + '/' + clientId + '/' + id)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 *  Provider Status
 */

export function statusProviderToggle(
	id: string | undefined,
	clientId: string | undefined,
	data: Partial<PartnerPayload>,
	source?: CancelTokenSource,
) {
	const promise = axios.patch(ENDPOINTS.PROVIDER_STATUS + '/' + clientId + '/' + id, data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
/**
 *  GetProviderSpeciality
 */

export function getProviderSpeciality(clientId: string | undefined, source?: CancelTokenSource) {
	const promise = axios.get(`${ENDPOINTS.PROVIDER_SPECIALITY}/${clientId}`)

	//@ts-ignore
	// Add cancel method to the promise
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}
/**
 *  GetProviderPartner
 */
export function getProviderPartner(clientId?: any, source?: CancelTokenSource) {
	const promise = axios.get(ENDPOINTS.CLIENT_PROVIDER)

	//@ts-ignore
	// Add cancel method to the promise
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}

export function getPartnerListByClientInProvider(
	id: string | undefined,
	params?: Partial<IProvidersParams>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get<IPagination<Partial<any>>>(
		onCreateEndpointsWithClientid(ENDPOINTS.PARTNER_LIST_BY_CLIENT),
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

// Get Provider Clients

export function getProviderClients(
	params: {
		limit: number | undefined
		page?: number | string
		search?: string
		sort?: string
	},
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get(onCreateEndpointsWithClientid(ENDPOINTS.CLIENT_FOR_PROVIDER_ROLE), {
		params: filteredParams,
	})

	//@ts-ignore
	// Add cancel method to the promise
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}

/**
 * * deleteCareProgram for delete CareTeam
 */
export function deleteProviderRole(id: string | undefined, source?: CancelTokenSource) {
	const promise = axios.delete<any>(
		onCreateEndpointsWithClientid(ENDPOINTS.PROVIDER_ROLE) + '/' + id,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

import _ from 'lodash'
import axios from 'services/axios'
import { CancelTokenSource } from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import { IPagination } from 'types/entities'
import { commonMessages } from 'utils/messages'
import { IFullMemberTasks, IMemberTasksParams } from './MemberTasks.interface'
import { MemberTaskPayload } from './MemberTask/MemberTask.types'
import { onCreateEndpointsWithClientid } from 'utils/common'
import { ITaskListingParams } from 'modules/TaskManagement/TaskListing/TaskListing.interface'

/**
 * * Get Member Tasks Listing
 */
export function getMemberTasks(params: Partial<IMemberTasksParams>, source?: CancelTokenSource) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get<IPagination<Partial<IFullMemberTasks>>>(
		// ENDPOINTS.PATIENT_TIME_TRACKER + '/' + clientId,
		onCreateEndpointsWithClientid(ENDPOINTS.PATIENT_TIME_TRACKER),
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function getMemberTask(
	params: any,
	clientId: string | undefined,
	source?: CancelTokenSource,
) {
	const promise = axios.get<IFullMemberTasks>(
		ENDPOINTS.GET_TIME_TRACKER_BY_ID + '/' + clientId + '/' + params?.id,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

// export function getMemberProviderTask(data?: any, source?: CancelTokenSource) {
// 	const promise = axios.get<IFullMemberTasks>(
// 		onCreateEndpointsWithClientid(ENDPOINTS.TASK_MANAGEMENT_PROVIDER),
// 		{
// 			...data,
// 		},
// 	)
// 	//@ts-ignore
// 	promise.cancel = () => {
// 		source?.cancel(commonMessages.queryCancelledMessage)
// 	}
// 	return promise
// }
export function getMemberProviderTask(
	params: Partial<ITaskListingParams>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get<IPagination<Partial<ITaskListingParams>>>(
		onCreateEndpointsWithClientid(ENDPOINTS.TASK_MANAGEMENT_PROVIDER),
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * * createMemberTask
 */
export function createMemberTask(
	id: string | any,
	data: Partial<MemberTaskPayload>,
	source?: CancelTokenSource,
) {
	delete data?.details
	delete data?.title
	delete data?.createdDate
	delete data?.profile
	// const promise = axios.post<MemberTaskPayload>(ENDPOINTS.PATIENT_TIME_TRACKER + '/' + clientId, {
	// 	...data,
	// 	patient: id,
	// })
	const sendData = id
		? {
				...data,
				patient: id,
		  }
		: { ...data }

	const promise = axios.post<MemberTaskPayload>(
		onCreateEndpointsWithClientid(ENDPOINTS.PATIENT_TIME_TRACKER),
		sendData,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function updateMemberTask(
	id: string | undefined,
	conditionId: string | undefined,
	clientId: string | undefined,
	data: Partial<MemberTaskPayload>,
	source?: CancelTokenSource,
) {
	delete data?.createdDate
	delete data?.profile
	delete data?.details
	delete data?.title
	const promise = axios.put<MemberTaskPayload>(
		ENDPOINTS.GET_TIME_TRACKER_BY_ID + '/' + clientId + '/' + conditionId,
		{
			...data,
			patient: id,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function createMemberJournalNoteTask(
	// clientId?: string | undefined,
	data?: Partial<MemberTaskPayload>,
	source?: CancelTokenSource,
) {
	delete data?.duration,
		delete data?.end_time,
		delete data?.note,
		delete data?.profile,
		delete data?.createdDate,
		delete data?.start_time,
		delete data?.taskType
	delete data?.taskManagement
	const promise = axios.post<MemberTaskPayload>(
		onCreateEndpointsWithClientid(ENDPOINTS.JOURNAL_NOTES),
		{
			// const promise = axios.post<MemberTaskPayload>(ENDPOINTS.JOURNAL_NOTES + '/' + clientId, {
			...data,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

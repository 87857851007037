import { useCallback, useEffect, useMemo, useRef } from 'react'
import {
	useMutation,
	UseMutationOptions,
	useQueryClient,
	UseQueryOptions,
	useQuery,
} from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { FormikHelpers } from 'formik'
import axios from 'axios'
import { AxiosResponse } from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import { scrollToError } from 'utils/common'
import { errorNotify, successNotify } from 'utils/toster'
import { commonMessages } from 'utils/messages'
import { useUrlQuery } from 'hooks/url'
import { IAxiosResponseWithPagination } from 'types/entities'
import { AddVitalInitialState, AddVitalPayload } from './Member.types'
import {
	createVitalProgram,
	getPartnerListingByClient,
	getProgramVitalsListing,
	getProviderList,
	getTrackingDevices,
	updateVitalProgram,
} from './Member.service'
import {
	IFullVitalsProgram,
	IProgramVitalsListingParams,
	ITrackingDevices,
	ITrackingDevicesParams,
} from '../Members.interfaces'
import { createVitalDevices } from '../../Devices/Devices.service'
import { getMemebersListing } from '../Members.service'
import { useProgramVitalEdit } from '../../Vitals/Vital/Vital.hooks'

const ERRORS = {
	phone: ['This number already exist in database', 'This number already exist in firebase'],
}
/**
 * Formik useInitial hook
 */
export function useInitial() {
	/**
	 * Variables and hooks section
	 */
	const alreadyMounted = useRef(false)

	const [vital] = useUrlQuery<any>()
	const id = vital?.vital
	const { data, isFetched } = useProgramVitalEdit({
		id,
		suspense: !alreadyMounted.current, //TO USE SUSPENSE ONCE ON MOUNT
	})
	/**
	 * *useEffects Section
	 * * @param useEffectName
	 */
	useEffect(() => {
		alreadyMounted.current = true
	}, [isFetched])
	/**
	 * useMemo section
	 */
	return useMemo(() => {
		return new AddVitalInitialState(id ? data?.data : {})
	}, [data?.data, id])
}

export function useSubmitHandler(props: any) {
	/**
	 * Variables and hooks section
	 */
	const [vitalParams] = useUrlQuery<any>()
	const navigate = useNavigate()
	const param = useParams<{ id: string }>()
	const id = ''
	const programId = param?.id
	const client = useQueryClient()
	/**
	 * * 1. Using useCreateProgramVital() hook to create a mutateAsync function for creating a new program vital.
	 * * 2. Using useUpdateVitalProgram() hook to create a mutateAsync function for updating an existing program vital, with the id specified in the params.
	 */
	const { mutateAsync: create } = useCreateProgramVital()
	const { mutateAsync: update } = useUpdateVitalProgram({ id: props?.vitalId })
	/**
	 * useCallback section
	 */
	return useCallback(
		async (form: AddVitalInitialState, formikHelpers: FormikHelpers<AddVitalInitialState>) => {
			formikHelpers.setSubmitting(true)
			form.program = programId
			formikHelpers.resetForm()
			try {
				if (!props?.vitalId) {
					await create(new AddVitalPayload(form))
					successNotify(commonMessages.createMessage)
				} else {
					await update(new AddVitalPayload(form))
					successNotify(commonMessages.updateMessage)
				}
				formikHelpers.setSubmitting(false)
				if (!vitalParams?.createVital) {
					props?.setOpenVitalDrawer(false)
				}
				client.invalidateQueries([ENDPOINTS.PROGRAMS_VITALS_LIST], {
					type: 'all',
				})
			} catch (err: any) {
				if (err.response.data && err.response.data.message) {
					Object.entries(ERRORS).forEach(([key, value]: [string, string[]]) => {
						if (value?.includes(err.response.data.message)) {
							formikHelpers.setFieldError(key, err.response.data.message)
						}
					})
					errorNotify(err.response.data.message)
				} else {
					formikHelpers.setErrors(err.response.data) //TODO - ADD ERRORS TRANSFORMATION TO FORM FORMAT
					errorNotify(commonMessages.errorHandlingMessage)
				}
				scrollToError()
			}
		},
		[client, create, id, navigate, update, props],
	)
}

/**
 * * Using the React Hooks useMutation to create a mutation function for creating a vital program.
 */
type ICreateProgramVitalProps = Partial<
	UseMutationOptions<AxiosResponse<AddVitalPayload>, any, Partial<AddVitalPayload>>
>

export function useCreateProgramVital({ ...rest }: ICreateProgramVitalProps = {}) {
	return useMutation<AxiosResponse<AddVitalPayload>, any, Partial<AddVitalPayload>>(
		createVitalProgram,
		{
			...rest,
		},
	)
}

/**
 * * Using the React Hooks useMutation to update a mutation function for updating a vital program.
 */
interface IUpdateVitalProps
	extends Partial<
		UseMutationOptions<AxiosResponse<AddVitalPayload>, any, Partial<AddVitalPayload>>
	> {
	id?: string
}

export function useUpdateVitalProgram({ id, ...rest }: IUpdateVitalProps) {
	return useMutation<AxiosResponse<AddVitalPayload>, any, Partial<AddVitalPayload>>(
		async (data) => await updateVitalProgram(id, data),
		{
			...rest,
		},
	)
}
/**
 * * Using the React Hooks useMutation to create a mutation function for creating a vitalDevices.
 */
type ICreateDevicesVitalProps = Partial<UseMutationOptions<AxiosResponse<any>, any, Partial<any>>>

export function useCreateDevicesVital({ clientId, ...rest }: ICreateDevicesVitalProps | any = {}) {
	return useMutation<AxiosResponse<any>, any, Partial<any>>(
		async (data) => await createVitalDevices(clientId, data),

		{
			...rest,
		},
	)
}
/**
 * * Using the React Hooks useQuery to create a  function getting a program vitals listing from an API, using the given id and source parameters.
 */
interface IGetProgramListingQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<IFullVitalsProgram>>>,
		Partial<IProgramVitalsListingParams> {}
export function useGetProgramListing({
	limit,
	page,
	sort,
	checkInInterval,
	week,
	trackingDevices,
	search,
	id,
	...rest
}: IGetProgramListingQueryProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<IFullVitalsProgram>>,
		any,
		IAxiosResponseWithPagination<Partial<IFullVitalsProgram>>
	>(
		[
			ENDPOINTS.PROGRAMS_VITALS_LIST,
			page,
			limit,
			sort,
			checkInInterval,
			week,
			trackingDevices,
			search,
			id,
		],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getProgramVitalsListing(
				id,
				source,

				{
					page,
					limit,
					sort,
					checkInInterval,
					week,
					trackingDevices,
					search,
				},
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: true,
			...rest,
		},
	)
}
/**
 * * Using the React Hooks useQuery to create a  function getting a Members listing from an API, using the given id and source parameters.
 */
interface IGetMemeberListingQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<any>>>,
		Partial<any> {}
export function useGetMemeberListing({
	clientId,
	limit,
	page,
	sort,
	checkInInterval,
	week,
	trackingDevices,
	search,
	id,
	...rest
}: IGetMemeberListingQueryProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<any>>,
		any,
		IAxiosResponseWithPagination<Partial<any>>
	>(
		[
			ENDPOINTS.PROGRAM_MEMBER,
			page,
			limit,
			sort,
			checkInInterval,
			week,
			trackingDevices,
			search,
			clientId,
			id,
		],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getMemebersListing(
				clientId,
				id,
				source,

				{
					page,
					limit,
					sort,
					checkInInterval,
					week,
					trackingDevices,
					search,
				},
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			...rest,
		},
	)
}

/**
 * * Using the React Hooks useQuery to create a  function  is making a getTrackingDevices API call.
 */

interface IITrackingDevicesQueryProps
	extends Partial<UseQueryOptions<AxiosResponse<ITrackingDevices<string[]>>>>,
		Partial<ITrackingDevicesParams> {}

export function useTrackingDevices({
	sort,
	clientId,
	limit,
	title,
	search,
	...rest
}: IITrackingDevicesQueryProps = {}) {
	return useQuery<
		AxiosResponse<ITrackingDevices<string[]>>,
		any,
		AxiosResponse<ITrackingDevices<string[]>>
	>(
		[ENDPOINTS.PROGRAM_DEVICES, sort, limit, title, search, clientId],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getTrackingDevices(
				clientId,
				{
					sort,
					limit,
					title,
					search,
				},
				source,
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: true,
			...rest,
		},
	)
}
/**
 * * Using the React Hooks useQuery to create a  function  method will then return a list of providers that match the source and options provided..
 */
// export function useProviderList(id: any, partnerId: any, page?: any, search?: any) {
// 	return useQuery(
// 		[ENDPOINTS.PROVIDER_ROLE_ALL, id, partnerId, search, page],
// 		() => {
// 			const CancelToken = axios.CancelToken
// 			const source = CancelToken.source()

// 			return getProviderList(id, source, {
// 				partner: partnerId,
// 				page,
// 				search,
// 			})
// 		},
// 		{
// 			keepPreviousData: true,
// 			refetchOnWindowFocus: false,
// 			enabled: !!id || !!partnerId,
// 		},
// 	)
// }

export function useProviderList({ id, partnerId, page, search }: any) {
	return useQuery(
		[ENDPOINTS.GOALS_CATEGORY, id, partnerId, search, page],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getProviderList(source, {
				partner: partnerId,
				page,
				search,
			})
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			// suspense: true,
			// enabled: !!id || !!partnerId,
			enabled: !!partnerId || true,
		},
	)
}
/**
 * * Using the React Hooks useQuery to create a  function getting a program vitals listing from an API, using the given id and source parameters.
 */
interface IGetPartnerListingByClientQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<any>>>,
		Partial<any> {}
export function useGetPartnerListByClient({
	id,
	limit,
	page,
	search,
	...rest
}: IGetPartnerListingByClientQueryProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<IFullVitalsProgram>>,
		any,
		IAxiosResponseWithPagination<Partial<IFullVitalsProgram>>
	>(
		[ENDPOINTS.PARTNER_LIST_BY_CLIENT, id, limit, page, search],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getPartnerListingByClient(id, source, { limit, page, search })
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: false,
			...rest,
			enabled: !!id,
		},
	)
}

import { CONTACT_POINT, IHumanName } from 'types/entities'
import { ISelectOption } from 'types/forms'
import { IFullProvider } from '../Provider.interfaces'
import { FIELD_NAMES } from '../Providers.constants'
import { getCurrentTimeZone } from 'utils/time'

export class ProviderPayload {
	id?: string
	active = false
	isEmailAlert = false
	isSmsAlert = false
	client: string | null = null
	humanName: Partial<IHumanName> = { text: '', given: '', family: '' }
	providerContactPoints: {
		text: string
		type: CONTACT_POINT | undefined
		typeId: string
		id?: string
		isDisabled?: boolean
	}[] = []
	email: string | null = null

	npi: number | null = null
	image?: (File | string | any) | null = null
	providerSpeciality: Array<{ providerSpeciality: string }> | undefined
	partner: any
	timezone: string | null | undefined = getCurrentTimeZone
	providerRole: {
		client: string
		roles: string
		roleLimit?: number
		roleOptions?: []
		clientOptions?: []
		isDisabled?: boolean
	}[] = [
		{ client: '', roles: '', roleOptions: [], clientOptions: [], roleLimit: 50, isDisabled: false },
	]
	constructor(data: ProviderInitialState) {
		this.addSpeciality(data?.providerSpeciality)
		this.addProviderRole(data?.providerRole)
		this.addIsActive(data[FIELD_NAMES.STATUS])
		this.addIsEmailAlert(data.isEmailAlert as boolean)
		this.addIsSMSAlert(data.isSmsAlert as boolean)
		this.addName(data.givenName, data.familyName)
		this.addEmail(data.email)
		this.addProviderContactPoints(data.providerContactPoints)
		this.addPartner(data?.partner)
		this.addAssets(data?.image)
		this.addTimezone(data?.timezone)
		this.addNpi(data.npi)
	}

	addTimezone(data: ISelectOption<string, string> | null | undefined) {
		this.timezone = data?.label
	}
	addNpi(npi: number | null) {
		if (npi) {
			this.npi = npi
		}
	}

	addPartner(partner: any) {
		if (partner) {
			this.partner = partner.value
		}
	}
	addEmail(email: string | undefined | null) {
		if (email) {
			this.email = email?.toLowerCase()
		}
	}

	addSpeciality(providerSpeciality: any) {
		const newSpeciality = providerSpeciality?.map((obj: any) => {
			return obj?.value
		})
		this.providerSpeciality = newSpeciality
	}
	// Add Provider Role
	addProviderRole(providerRole: ProviderInitialState['providerRole'] | undefined | any) {
		if (providerRole?.length) {
			this.providerRole = providerRole.map(
				(item: { id: string; client: { value: string }; roles: { value: string } }) => {
					if (item?.id) {
						return {
							client: item?.client?.value,
							roles: item?.roles?.value,
							id: item?.id,
						}
					} else {
						return {
							client: item?.client?.value,
							roles: item.roles?.value,
						}
					}
				},
			)
		}
	}
	addAssets(assets: string) {
		this.image = assets
	}
	addIsActive(status: 'isActive' | 'isInactive') {
		if (status === 'isActive') {
			this.active = true
		}
		if (status === 'isInactive') {
			this.active = false
		}
	}

	addIsEmailAlert(isEmailAlert: boolean) {
		this.isEmailAlert = isEmailAlert
	}
	addIsSMSAlert(isSmsAlert: boolean) {
		this.isSmsAlert = isSmsAlert
	}
	addClient(client: ISelectOption | null | undefined) {
		if (client?.value) {
			this.client = client.value
		}
	}
	addName(givenName: string | undefined | null, familyName: string | undefined | null) {
		const fullName = `${givenName} ${familyName}`
		this.humanName.text = fullName || ''
		if (givenName) {
			this.humanName.given = givenName
		}
		if (familyName) {
			this.humanName.family = familyName
		}
	}
	addProviderContactPoints(
		contactPoints: ProviderInitialState['providerContactPoints'] | undefined | any,
	) {
		if (contactPoints?.length) {
			this.providerContactPoints = contactPoints.map(
				(item: {
					id: string
					typeId: {
						id: string
						value: string
					}
					text: string
				}) => {
					return {
						text:
							item?.typeId?.value === 'phone' || item?.typeId?.value === 'fax'
								? `+1${item?.text}`
								: item?.text.toLowerCase(),
						typeId: item?.typeId?.id,
						type: item?.typeId?.value,
						id: item?.id,
					}
				},
			)
		}
	}
}

export class ProviderInitialState {
	status: 'isActive' | 'isInactive' = 'isActive'
	isEmailAlert: boolean | undefined
	isSmsAlert: boolean | undefined
	givenName: string | null = null
	familyName: string | null = null
	email: string | null = null
	image: (File | string | any) | null = null
	photo: (File | string | any) | null = null
	providerContactPoints: {
		text: string
		type: { label: string; value: CONTACT_POINT } | undefined
		typeId: ISelectOption | undefined
		id?: string
		isDisabled?: boolean
	}[] = [
		{
			text: '',
			type: { label: 'Phone', value: CONTACT_POINT.PHONE },
			typeId: { label: '', value: '' },
			isDisabled: true,
		},
	]
	providerClientRole: [] = []
	npi: number | null = null
	partner: any
	timezone?: ISelectOption | null = null

	countryCode?: ISelectOption | null = null

	providerSpeciality:
		| {
				providerSpeciality: string | null
		  }[]
		| undefined
	isFetched?: boolean | null | undefined = true

	providerRole: {
		client: string
		roles?: string
		roleOptions?: []
		clientOptions?: []
		roleLimit?: number
		isDisabled?: boolean
	}[] = [
		{
			client: '',
			roles: '',
			roleOptions: [],
			clientOptions: [],
			roleLimit: 50,
			isDisabled: false,
		},
	]
	constructor(data: Partial<IFullProvider> | undefined) {
		this.addIsActive(data?.active)
		this.addIsEmailAlert(data?.isEmailAlert)
		this.addIsSMSAlert(data?.isSmsAlert)
		this.addContacts(data?.providerContactPoints)
		this.addName(data?.humanName)
		this.addEmail(data?.email)
		this.addPartner(data?.partner)
		this.addProviderRole(data?.providerRole)
		this.addNpi(data?.npi)
		this.addSpeciality(data?.providerSpeciality)
		this.addTimezone(data?.options, data?.timezone)
		this.addAssets(data?.image ? data?.image : data?.photo)
		this.addProviderClientRole(data?.providerRole)
		this.addCountryCode(data?.countryCode)
		this.addIsFetched(data?.isFetched)
	}
	addIsFetched(isFetched: boolean | null | undefined) {
		if (isFetched) {
			this.countryCode = {
				label: '+1',
				value: '+1',
			}
		}
		this.isFetched = isFetched
	}
	addTimezone(options: ISelectOption[] | undefined, timezone = getCurrentTimeZone) {
		if (options) {
			this.timezone = options.find((item) => {
				return item.label === timezone
			})
		}
	}

	addCountryCode(countryCode: ISelectOption[] | undefined) {
		if (countryCode) {
			this.countryCode = {
				label: '+1',
				value: '+1',
			}
		}
	}

	addNpi(npi?: number | null) {
		if (npi) {
			this.npi = npi
		}
	}
	addAssets(assets: any) {
		this.image = assets
		this.photo = assets
	}

	addPartner(partner: string | any) {
		if (partner) {
			this.partner = {
				value: partner?.id,
				label: partner?.companyName,
			}
		}
	}
	addSpeciality(providerSpeciality: any) {
		if (providerSpeciality) {
			const newArray = providerSpeciality.map((obj: any) => ({
				value: obj?.speciality?.id,
				label: obj?.speciality?.speciality,
			}))
			this.providerSpeciality = newArray
		}
	}

	addEmail(email: string | undefined) {
		if (email) {
			this.email = email
		}
	}
	addName(name: IHumanName | undefined) {
		if (name?.given) {
			this.givenName = name.given
		}
		if (name?.family) {
			this.familyName = name.family
		}
	}
	addIsActive(isActive?: boolean) {
		if (typeof isActive === 'boolean') {
			if (isActive) {
				this.status = 'isActive'
			}
			if (!isActive) {
				this.status = 'isInactive'
			}
		}
	}
	addIsEmailAlert(isEmailAlert?: boolean) {
		if (isEmailAlert) {
			this.isEmailAlert = isEmailAlert
		}
	}
	addIsSMSAlert(isSmsAlert?: boolean) {
		if (isSmsAlert) {
			this.isSmsAlert = isSmsAlert
		}
	}

	addContacts(providerContactPoints: IFullProvider['providerContactPoints'] | undefined | any) {
		if (providerContactPoints?.length) {
			this.providerContactPoints = providerContactPoints.map((providerContact: any) => {
				return {
					text:
						providerContact?.contactPoint?.typeId?.slug == 'phone' ||
						providerContact?.contactPoint?.typeId?.slug == 'fax'
							? providerContact?.contactPoint?.text?.replace(/\+1/g, '')
							: providerContact?.contactPoint?.text,
					typeId: {
						label: providerContact?.contactPoint?.typeId?.globalCodesName,
						value: providerContact?.contactPoint?.typeId?.slug,
						id: providerContact?.contactPoint?.typeId?.id,
					},
				}
			})
		}
	}
	// Add Provider Role
	addProviderRole(providerRole: any) {
		if (providerRole?.length) {
			this.providerRole = providerRole.map((item: any) => {
				return {
					roleLimit: 50,
					isDisabled: false,

					client: {
						label: item?.client?.title,
						value: item?.client?.id,
					},
					roles: {
						label: item?.roles?.roleName,
						value: item?.roles?.id,
						slug: item?.roles?.slug,
					},
					id: item?.id,
				}
			})
		}
	}
	addProviderClientRole(providerClientRole: any) {
		if (providerClientRole?.length) {
			this.providerClientRole = providerClientRole
		}
	}
}

import { useNavigate, useParams } from 'react-router-dom'
import { Form, useFormikContext } from 'formik'
import Button from 'components/Button'
import { debounceHandler, getClientDetails } from 'hooks/common'
import FormikInputField from 'components/FormikInputField'
import { FormikAdvanceSelect } from 'components/FormikAdvancedSelect'
import FormikRadioButtonField from 'components/FormikRadioButtonField'
import { useUrlQuery } from 'hooks/url'
import { FIELD_NAMES, IS_ACTIVE_OPTIONS } from '../Roles.constants'
import { IRoleBaseParams } from './Role.interface'
import { RoleBaseInitialState } from './Role.types'
import { useGetRole } from '../Roles.hooks'
import { useEffect, useState } from 'react'
import { DROPDOWN_LIMIT } from 'const/common'

let parentRolePage = 1

export function RoleComponent() {
	/**
	 * Context Section
	 */

	const context = useFormikContext<RoleBaseInitialState>()
	const [urlParams] = useUrlQuery<IRoleBaseParams>()

	/**
	 * * State Section
	 */
	const [searchRole, setSearchRole] = useState('')
	const [parentRole, setParentRole] = useState<any>([])
	const [searchParentRole, setSearchParentRole] = useState<any>([])
	const [parentRolePageProp, setParentRolePageProp] = useState(1)
	const [totalParentRolePages, setTotalParentRolePages] = useState(0)
	/**
	 * *Custom hooks Section
	 */

	const params = useParams()
	const navigate = useNavigate()
	const isRole: any = useGetRole({
		...urlParams,
		client: getClientDetails()?.clientId,
		id: urlParams?.id,
		search: searchRole,
		page: parentRolePageProp,
		limit: DROPDOWN_LIMIT.LIMIT,
	})
	/**
	 * * Function Section
	 */
	const handleParentRoleSearch = (data: string | undefined) => {
		setSearchRole(data || '')
		setParentRolePageProp(1)
	}
	const handleParentRoleSearchInputChange = debounceHandler(handleParentRoleSearch)

	const OnParentRoleScrollDown = () => {
		if (totalParentRolePages > parentRolePage && !isRole?.isFetching) {
			parentRolePage = parentRolePage + 1
			setParentRolePageProp(parentRolePage)
		}
	}
	/**
	 * *useEffects Section
	 * @param useEffectName
	 */
	useEffect(() => {
		if (isRole?.data?.data?.items?.length && !searchRole) {
			const existingValues = parentRole?.map((option: any) => option.value)
			const items = isRole?.data?.data?.items
				?.filter((item: any) => !existingValues.includes(item.id))
				?.map((item: any) => ({
					...item,
					label: item?.roleName,
					value: item?.id,
				}))
			setSearchParentRole([])
			setParentRole([...parentRole, ...items])
		}
	}, [isRole?.data?.data?.items])

	useEffect(() => {
		if (isRole?.data?.data?.items?.length && searchRole) {
			const existingValues = parentRole?.map((option: any) => option.value)
			const items = isRole?.data?.data?.items
				?.filter((item: any) => !existingValues.includes(item.id))
				?.map((item: any) => ({
					...item,
					label: item?.roleName,
					value: item?.id,
				}))
			setParentRole([])
			setSearchParentRole([...searchParentRole, ...items])
		}
	}, [isRole?.data?.data?.items])

	const isRoleDataFetching = isRole?.data?.isFetching ?? false

	/**
	 * *Function Section
	 */

	useEffect(() => {
		if (isRole?.data?.data?.meta?.totalPages) {
			setTotalParentRolePages(isRole?.data?.data?.meta?.totalPages)
		}
	}, [isRole?.data?.data?.meta?.totalPages])

	/**
	 * *JSX Section
	 * @returns Role Form
	 */

	return (
		<div className="px-4 md:px-auto flex flex-col">
			<Form>
				<>
					<div className=" grid md:grid-cols-2 gap-x-4 gap-y-2">
						<FormikInputField
							name={FIELD_NAMES.ROLE_NAME}
							label="Role Name"
							placeholder="Role Name"
							require="true"
						/>

						<FormikAdvanceSelect
							isClearable
							name={FIELD_NAMES.PARENT_ROLE}
							isLoading={isRoleDataFetching}
							label="Parent Role"
							placeholder="Select Parent Role"
							className="capitalize"
							onInputChange={handleParentRoleSearchInputChange}
							options={searchRole ? searchParentRole : parentRole}
							onCustomMenuScrollToBottom={OnParentRoleScrollDown}
						/>

						<FormikRadioButtonField
							name={FIELD_NAMES.IS_ACTIVE}
							options={IS_ACTIVE_OPTIONS}
							className="pb-2"
							classNames={{
								inlineLabel: 'text-[#98A0A6] text-[16px] !font-normal',
								message: 'text-red-400',
								inputWrapper: 'ml-4 whitespace-nowrap',
								input: 'pl-0',
							}}
						/>
					</div>
				</>
				<div className="flex gap-4 mt-4 justify-end flex-col md:flex-row">
					<Button
						className="mt-2"
						buttonType="button"
						type="default"
						disabled={context.isSubmitting}
						onClick={() => navigate(-1)}
					>
						Cancel
					</Button>
					<Button
						className="mt-2"
						buttonType="submit"
						type="primary"
						disabled={context.isSubmitting}
					>
						{urlParams?.id || params?.id ? 'Update' : 'Create'}
					</Button>
				</div>
			</Form>
		</div>
	)
}

import axios from 'services/axios'
import { AssignedProviderRolePayload } from './AssignedProvidersRole/AssignedProviderRole.types'
import { CancelTokenSource } from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import { commonMessages } from 'utils/messages'
import { IAssignProviderRoleParams } from './AssignedProvidersRole/AssignedProviderRole.interface'
import { IPagination } from 'types/entities'
import _ from 'lodash'

/**
 * * Create Provider Role
 */
export function createProviderRole(
	clientId: string | undefined,
	data: Partial<AssignedProviderRolePayload>,
	source?: CancelTokenSource,
) {
	const promise = axios.post<AssignedProviderRolePayload>(
		ENDPOINTS.CREATE_ASSIGN_ROLES_PERMISSION + '/' + clientId,
		data,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
// CREATE_ASSIGN_ROLES_PERMISSION

/**
 * * GET request for all Specific modules.
 */
export function getProvidersForClientRole(
	clientId?: string | undefined | any,
	params?: Partial<IAssignProviderRoleParams>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<any>>>(
		ENDPOINTS.PROVIDER_ROLE_ALL + '/' + clientId,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * * GET request for all Specific modules.
 */
export function getRolesForClient(
	clientId?: string | undefined | any,
	params?: Partial<IAssignProviderRoleParams>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<any>>>(
		ENDPOINTS.GET_ROLES_FOR_ALL_CLIENT + '/' + clientId,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

import { useCallback, useEffect, useMemo, useRef } from 'react'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import {
	useMutation,
	UseMutationOptions,
	useQueryClient,
	useQuery,
	UseQueryOptions,
} from '@tanstack/react-query'
import { FormikHelpers } from 'formik'
import axios, { AxiosResponse, CancelTokenSource } from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import { APP_ROUTES } from 'const/router'
import { getClientDetails, useMyProfile } from 'hooks/common'
import { scrollToError, timezoneDropdownOptions } from 'utils/common'
import { axios as serviceAxios } from 'services/axios'
import { commonMessages } from 'utils/messages'
import { errorNotify, successNotify } from 'utils/toster'
import {
	createProvider,
	deleteProviderRole,
	getProvider,
	getProviderClients,
	getProviderPartner,
	getProviderSpeciality,
	statusToggle,
	updateProvider,
} from '../Provider.service'
import { ProviderInitialState, ProviderPayload } from './Provider.types'
import { IFullProvider, IProvidersParams } from '../Provider.interfaces'
import axiosLibrary from 'axios'
import { useUrlQuery } from 'hooks/url'

//* Formik useInitial hook
export function useInitial() {
	/**
	 * *Variable and hooks section
	 */
	const alreadyMounted = useRef(false)
	const location = useLocation()
	const profile = useMyProfile()
	const [urlQuery] = useUrlQuery<IProvidersParams>()
	let id = urlQuery?.id

	if (matchPath(APP_ROUTES.ME, location.pathname)) {
		id = profile?.id
	}
	const { data, isFetched, isFetching } = useProvider({
		id,
		clientId: getClientDetails()?.clientId,
		suspense: !alreadyMounted.current, //TO USE SUSPENSE ONCE ON MOUNT
	})

	/**
	 * *useEffects Section
	 * @param useEffectName
	 */
	useEffect(() => {
		alreadyMounted.current = true
	}, [isFetched])
	/**
	 * *useMemo Section
	 */
	return useMemo(() => {
		const initialData = {
			...data?.data,
			options: timezoneDropdownOptions(),
			isFetched,
		}
		return new ProviderInitialState(initialData as IFullProvider)
	}, [data?.data, isFetching])
}
/**
 * *Formik useSubmitHandler hook
 */
export function useSubmitHandler() {
	/**
	 * *Variable and hooks section
	 */
	const navigate = useNavigate()
	const { mutateAsync: create } = useCreateProvider({ clientId: getClientDetails()?.clientId })

	const [urlQuery] = useUrlQuery<IProvidersParams>()
	let id: any = urlQuery?.id
	const location = useLocation()
	const profile = useMyProfile()
	if (matchPath(APP_ROUTES.ME, location.pathname)) {
		id = profile?.id
	}
	const { mutateAsync: update } = useUpdateProvider({ id, clientId: getClientDetails()?.clientId })
	const client = useQueryClient()
	/**
	 * *useCallback Section
	 */

	return useCallback(
		async (form: ProviderInitialState, formikHelpers: FormikHelpers<ProviderInitialState>) => {
			formikHelpers.setSubmitting(true)
			try {
				if (!id) {
					const result = await create(new ProviderPayload(form))
					successNotify(commonMessages.createMessage)

					if (result?.data?.id) {
						navigate(APP_ROUTES.PROVIDER_PROFILE + `?id=${result?.data?.id}`)
					}
				}
				if (id) {
					await update(new ProviderPayload(form))
					successNotify(commonMessages.updateMessage)
					const isUpdateProfile = matchPath(APP_ROUTES.ME, location.pathname)
					isUpdateProfile ? navigate(-1) : navigate(APP_ROUTES.PROVIDER_PROFILE + `?id=${id}`)
				}
				formikHelpers.resetForm()
				formikHelpers.setSubmitting(false)
				client.invalidateQueries([ENDPOINTS.PROVIDERS, ENDPOINTS.ME], {
					type: 'all',
				})
				client.invalidateQueries([ENDPOINTS.ME], {
					type: 'all',
				})
			} catch (err: any) {
				formikHelpers.setErrors(err?.response?.data) //TODO - ADD ERRORS TRANSFORMATION TO FORM FORMAT
				scrollToError()
				errorNotify(err?.response?.data?.message)
			}
		},
		[client, create, id, navigate, update],
	)
}

/**
 * * Using the React Hooks useMutation to create a mutation function for creating a Provider.
 */

type ICreateProviderProps = Partial<
	UseMutationOptions<AxiosResponse<ProviderPayload>, any, Partial<ProviderPayload>>
>

export function useCreateProvider({ clientId, ...rest }: ICreateProviderProps | any = {}) {
	return useMutation<AxiosResponse<ProviderPayload>, any, Partial<ProviderPayload>>(
		async (data) => await createProvider(clientId, data),
		{
			...rest,
		},
	)
}
/**
 * * Using the React Hooks useMutation to create a mutation function for updating a provider.
 */

interface IUpdateProfileProps
	extends Partial<
		UseMutationOptions<AxiosResponse<ProviderPayload>, any, Partial<ProviderPayload>>
	> {
	id?: string
}

export function useUpdateProvider({ id, clientId, ...rest }: IUpdateProfileProps | any) {
	return useMutation<AxiosResponse<ProviderPayload>, any, Partial<ProviderPayload>>(
		async (data) => await updateProvider(id, clientId, data),
		{
			...rest,
		},
	)
}

/**
 * * Using the React Hooks useQuery to create a query function for edit a Provider.
 */

interface IProviderQueryProps
	extends Partial<UseQueryOptions<AxiosResponse<IFullProvider>>>,
		Partial<IFullProvider> {
	id?: string
	clientId?: string
}

export function useProvider({ id, clientId, ...rest }: IProviderQueryProps) {
	return useQuery<AxiosResponse<IFullProvider>, any, AxiosResponse<IFullProvider>>(
		[ENDPOINTS.PROVIDERS, id, clientId],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getProvider(id, clientId, source)
		},
		{
			keepPreviousData: false,
			refetchOnWindowFocus: false,
			suspense: true,
			enabled: !!id,
			...rest,
		},
	)
}

/**
 * * Using a React hooks function for Provider Speciality.
 */

export function useProviderSpeciality({ clientId }: { clientId: string }) {
	return useQuery<any>(
		[ENDPOINTS.PROVIDER_SPECIALITY, clientId],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getProviderSpeciality(clientId, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	)
}
/**
 * * Using a React hooks function for Client Provider.
 */

export function useProviderPartner(clientId: any) {
	return useQuery<any>(
		[ENDPOINTS.CLIENT_PROVIDER],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getProviderPartner(clientId, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			// suspense: true,
		},
	)
}
interface IUpdateStatusToggle {
	active?: true | false
	clientId?: string
}
interface IUpdateStatusToggleProps
	extends Partial<UseMutationOptions<AxiosResponse<IProviderQueryProps>, any, IUpdateStatusToggle>>, //<AxiosResponse<received data from api>, any, payload data send to>
		IUpdateStatusToggle {}

export function useStatusUpdateToggle({
	clientId,
	active,
	...rest
}: IUpdateStatusToggleProps = {}) {
	//@ts-ignore
	const CancelToken = axiosLibrary.CancelToken
	const source = CancelToken.source()
	return useMutation<AxiosResponse<IProviderQueryProps>, any, any>(
		async (id: string) => await statusToggle(id, clientId, { active }, source),
		{
			...rest,
		},
	)
}

export function useTwoFA({ clientId, ...rest }: any) {
	return useMutation<AxiosResponse<any>, any, any>(
		(data) => twoFactorAuthentication(clientId, data),
		{
			...rest,
		},
	)
}

function twoFactorAuthentication(
	clientId: string | undefined,
	data: any,
	source?: CancelTokenSource,
) {
	const promise = serviceAxios.post<any>(ENDPOINTS.TWO_FACTOR_ENABLE_DISBALE + '/' + clientId, data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function useProviderClients({
	limit,
	page,
	search,
	isEnabled,
	sort,
}: {
	limit?: number
	page?: number
	search?: string
	isEnabled?: boolean
	sort?: string
}) {
	const [urlParams] = useUrlQuery<{ clientSearch: string }>()
	const clientSearch = urlParams.clientSearch
	return useQuery<any>(
		[ENDPOINTS.CLIENT_FOR_PROVIDER_ROLE, clientSearch, limit, page, search, sort],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getProviderClients(
				{
					limit,
					page,
					search: search || clientSearch,
				},
				source,
			)
		},
		{
			suspense: false,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: isEnabled,
		},
	)
}
//

/**
 * * Using React Hook function to delete a Fee Schedule.
 */
export function useDeleteProviderRole() {
	//@ts-ignore
	const CancelToken = axiosLibrary.CancelToken
	const source = CancelToken.source()
	return useMutation<AxiosResponse<any>, any, any>(
		async (id: string) => await deleteProviderRole(id, source),
	)
}

import TableSortHeader from 'components/TableSortHeader'
import { IBaseWithId, ISlots } from 'types/entities'

export const scheduleColumns = [
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Schedule Name</TableSortHeader>,
		accessor: 'col1', // accessor is the "key" in the data
		name: 'scheduleName',
		sortable: true,
	},

	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Schedule Type</TableSortHeader>,
		accessor: 'col2',
		name: 'scheduleType',
		sortable: true,
	},

	{
		Header: 'Range',
		accessor: 'col3',
		name: 'range',
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Status</TableSortHeader>,
		accessor: 'col4',
		sortable: true,
		name: 'notAvailable',
	},
	{
		Header: 'Actions',
		accessor: 'col5',
		name: 'actions',
	},
]

export enum SlotAvailability {
	available = 'Available',
	notAvailable = 'Not Available',
}

export interface IScheduleParams {
	id?: string
	search: string
	name: string
	scheduleType: string
	scheduleName: string
	page: number
	sort: string
	provider?: string
	schedule?: string
	limit: number
	partner: string
	client?: any
	partnerName?: any
	providerName?: any
	isCalendarSchedule?: any
}

export interface IAddSchedule extends IBaseWithId {
	scheduleName: string
	scheduleType: TIME_RANGE
	notAvailable: boolean
	partner: string
	slots: ISlots[]
	weekRange: WEEK_RANGE
	startDate: string
	endDate: string
	date?: number | string
	week?:
		| {
				day: string
		  }[]
		| undefined
}
//
export enum FIELD_NAMES {
	SCHEDULE_NAME = 'scheduleName',
	SCHEDULE_TYPE = 'scheduleType',
	SCHEDULE_TYPE_ID = 'scheduleTypeId',

	DATE = 'date',
	AVAILABILITY = 'notAvailable',
	WEEK_RANGE = 'weekRange',
	WEEK_RANGE_ID = 'weekRangeId',
	STARTDATE = 'startDate',
	ENDDATE = 'endDate',
	SLOTS = 'slots',
	WEEK = 'week',
	PROVIDER = 'provider',
	CLIENT = 'client',
	PARTNER = 'partner',
}

export enum RANGE_TYPE {
	start = 'start',
	end = 'end',
}

export interface Range {
	start: Date | null
	end: Date | null
}

export enum TIME_RANGE {
	DAY = 'day',
	WEEK = 'week',
	DATE_RANGE = 'dateRange',
}

export enum WEEK_RANGE {
	Month1 = '1',
	Month3 = '3',
	Month6 = '6',
	Month9 = '9',
	Month12 = '12',
}
export enum ScheduleType {
	week = 'week',
	day = 'day',
	dateRange = 'dateRange',
}
export const startTimeOptions = [
	{ value: '01', label: '01', name: 'startTime' },
	{ value: '02', label: '02', name: 'startTime' },
	{ value: '03', label: '03', name: 'startTime' },
	{ value: '04', label: '04', name: 'startTime' },
	{ value: '05', label: '05', name: 'startTime' },
	{ value: '06', label: '06', name: 'startTime' },
	{ value: '07', label: '07', name: 'startTime' },
	{ value: '08', label: '08', name: 'startTime' },
	{ value: '09', label: '09', name: 'startTime' },
	{ value: '10', label: '10', name: 'startTime' },
	{ value: '11', label: '11', name: 'startTime' },
	{ value: '12', label: '12', name: 'startTime' },
]

export const startMinutesOption = [
	{ value: '05', label: '05', name: 'startMinute' },
	{ value: '10', label: '10', name: 'startMinute' },
	{ value: '15', label: '15', name: 'startMinute' },
	{ value: '20', label: '20', name: 'startMinute' },
	{ value: '25', label: '25', name: 'startMinute' },
	{ value: '30', label: '30', name: 'startMinute' },
	{ value: '35', label: '35', name: 'startMinute' },
	{ value: '40', label: '40', name: 'startMinute' },
	{ value: '45', label: '45', name: 'startMinute' },
	{ value: '50', label: '50', name: 'startMinute' },
	{ value: '55', label: '55', name: 'startMinute' },
	{ value: '60', label: '60', name: 'startMinute' },
]

export const startAmPmOption = [
	{ value: 'AM', label: 'AM', name: 'startPeriod' },
	{ value: 'PM', label: 'PM', name: 'startPeriod' },
]

//  End Time
export const endTimeOptions = [
	{ value: '01', label: '01', name: 'endTime' },
	{ value: '02', label: '02', name: 'endTime' },
	{ value: '03', label: '03', name: 'endTime' },
	{ value: '04', label: '04', name: 'endTime' },
	{ value: '05', label: '05', name: 'endTime' },
	{ value: '06', label: '06', name: 'endTime' },
	{ value: '07', label: '07', name: 'endTime' },
	{ value: '08', label: '08', name: 'endTime' },
	{ value: '09', label: '09', name: 'endTime' },
	{ value: '10', label: '10', name: 'endTime' },
	{ value: '11', label: '11', name: 'endTime' },
	{ value: '12', label: '12', name: 'endTime' },
]

export const endMinutesOption = [
	{ value: '05', label: '05', name: 'endMinute' },
	{ value: '10', label: '10', name: 'endMinute' },
	{ value: '15', label: '15', name: 'endMinute' },
	{ value: '20', label: '20', name: 'endMinute' },
	{ value: '25', label: '25', name: 'endMinute' },
	{ value: '30', label: '30', name: 'endMinute' },
	{ value: '35', label: '35', name: 'endMinute' },
	{ value: '40', label: '40', name: 'endMinute' },
	{ value: '45', label: '45', name: 'endMinute' },
	{ value: '50', label: '50', name: 'endMinute' },
	{ value: '55', label: '55', name: 'endMinute' },
	{ value: '60', label: '60', name: 'endMinute' },
]

export const endAmPmOption = [
	{ value: 'AM', label: 'AM', name: 'endPeriod' },
	{ value: 'PM', label: 'PM', name: 'endPeriod' },
]

export const weekDaysAvailableFor = [
	{ value: 'This month', label: 'This month' },
	{ value: '3 months', label: '3 months' },
	{ value: '6 months', label: '6 months' },
]
export const MONTHS_OPTION: {
	label: string
	value: WEEK_RANGE
}[] = [
	{ label: '1 month', value: WEEK_RANGE.Month1 },
	{ label: '3 months', value: WEEK_RANGE.Month3 },
	{ label: '6 months', value: WEEK_RANGE.Month6 },
	{ label: '9 months', value: WEEK_RANGE.Month9 },
	{ label: '12 months', value: WEEK_RANGE.Month12 },
]

export enum SCHEDULES_FILTER_FIELDS {
	SEARCH = 'Search',
	PARTNER = 'Partner',
	PROVIDER = 'Provider',
}

import axios from 'services/axios'
import { ENDPOINTS } from 'const/endpoints'

interface IActivityLogPayLoad {
	information: IUserActivityLogger
	status: boolean
	device: string
	clientType: string
	errorType?: string
}
interface IUserActivityLogger {
	action: string
	buttonName?: string
	searched: string
	screenName: string
	clientName: string
	tokenUserId: string
}
class Logger {
	logs: Array<{
		information: any
		status: boolean
		device: string
		clientType: string
		errorType?: string
	}>

	constructor() {
		this.logs = []
	}

	// async log(action: string, details: any): Promise<void> {
	async log(details: IUserActivityLogger): Promise<void> {
		const logEntry = {
			information: details,
			status: true,
			device: 'webApp',
			clientType: 'web/frontend',
			// errorType: 'activity-log',
		}
		this.logs.push(logEntry)
		await this.sendToServer(logEntry)
	}

	async sendToServer(logEntry: IActivityLogPayLoad): Promise<void> {
		try {
			const promise = await axios.post<any>(ENDPOINTS.ACTIVITY_LOG_SAVE, logEntry)
			console.log(promise)
		} catch (error) {
			console.error('Failed to send log entry:', error)
		}
	}
}

const logger = new Logger()
export default logger

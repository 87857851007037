import axios from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import { errorNotify, successNotify } from 'utils/toster'
import { CommonMessages, commonMessages } from 'utils/messages'
import { ReactComponent as ErrorFound } from 'assets/images/PageNotWorking.svg'
import Button from 'components/Button'
import { getLocalStorageDetails, onParseJSON } from 'utils/common'
import { LOCAL_STORAGE } from 'const/common'
import { socket } from 'components/SocketConectivity/socketConnection'

export function createActivityLog(error: any) {
	const domainName = window.location.hostname
	const screen = window.location.pathname.slice(1)
	const serializedError = JSON.stringify(error?.stack as Error)

	if (serializedError) {
		const requestData = {
			source: 'web/frontend',
			action: error?.name,
			screen,
			error: serializedError ?? '',
			isEmail: true,
			domainName,
		}
		const token = localStorage.getItem('token')
		const config = {
			url: `${process.env.REACT_APP_API_URL}${ENDPOINTS.ACTIVITY_LOG}`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			method: 'post',
			data: requestData,
		}
		axios(config)
			.then(({ data }) => {
				console.log(data)
			})
			.catch((err: string) => {
				errorNotify(err)
			})
	}
}

export function ErrorFallback({
	error,
	resetErrorBoundary,
}: {
	error: Error
	resetErrorBoundary: () => void
}) {
	createActivityLog(error as Error)
	return (
		<div role="alert" className="flex flex-col items-center justify-center h-full flex-1 gap-4">
			<p>{`${CommonMessages?.ERROR_HANDLING_MESSAGE}:`}</p>
			<pre>
				{' '}
				<ErrorFound />{' '}
			</pre>
			<Button onClick={resetErrorBoundary}>Try again</Button>
		</div>
	)
}
/** Func handleStorageChange is used to get local storage and process permission
 * data, Iterate through each service object in permission data and process it
 *  invoke sevice object and return route details
 *
 * @returns Return the array containing processed route details
 */
export const handleStorageChange = () => {
	const permission: any = getLocalStorageDetails(LOCAL_STORAGE?.PERMISSION_DETAIL)
	const routeDetails: any = []
	if (permission) {
		const permissionData = onParseJSON(permission)
		if (permissionData) {
			Array.isArray(permissionData) &&
				permissionData?.forEach((serviceObj: any) => {
					processServiceObject(serviceObj, routeDetails)
				})
		}
	}
	return routeDetails
}
/**
 * Handles actions to be taken when a network error occurs.
 * Notifies the user to check their internet connection.
 */
export const onNetworkError = () => {
	return errorNotify(commonMessages?.checkInternetConnection)
}

/**
 * Handle beforeunload event to disconnect the socket
 * Notifies the user to check their socket connection.
 */

export const onBeforeUnload = () => {
	if (socket?.connected) {
		socket?.disconnect()
	}
}
/**
 * Handles actions to be taken when a network success occurs.
 * Notifies the user that they are online now.
 */
export const onNetworkSuccess = () => {
	successNotify(commonMessages?.backToOnline)
}
/** Func processModuleObject is used for Processes a module object  by iterating
 *  through its provider modules and pushing provider screens to full details
 */
const processModuleObject = (moduleObj: any, fullDetails: any[]) => {
	const {
		providerModule: { providerScreens },
	} = moduleObj
	fullDetails.push(...(providerScreens || []))
}
/** Func processServiceObject is used for Processes a service object  by iterating
 *  through its provider service modules and invoking the 'processModuleObject'
 *  function for each module.
 */
export const processServiceObject = (serviceObj: any, fullDetails: any[]) => {
	const { providerServiceModules } = serviceObj
	providerServiceModules?.forEach((moduleObj: any) => {
		processModuleObject(moduleObj, fullDetails)
	})
}
/**
 * Attaches an event listener to the global window object.
 *
 * @param {string} addEventName - The name of the event to listen for.
 * @param {Function} addFunction - The function to be executed when the event occurs.
 */
export const windowAddEventListener = (addEventName: string, addFunction: any) => {
	window.addEventListener(addEventName, addFunction)
}
/**
 * Removes an event listener from the global window object.
 *
 * @param {string} removeEventName - The name of the event for which the listener was added.
 * @param {Function} removeFunction - The function to be removed as the event listener.
 */
export const windowRemoveEventListener = (removeEventName: string, removeFunction: any) => {
	window.removeEventListener(removeEventName, removeFunction)
}

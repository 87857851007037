export const commonMessages = {
	errorHandlingMessage: 'Something went wrong',
	queryCancelledMessage: 'Query was cancelled by React Query',
	createMessage: 'Created Successfully',
	deleteMessage: 'Deleted Successfully',
	updateMessage: 'Updated Successfully',
	assignedMessage: 'Assigned Successfully',
	sentMessage: 'The message has been sent successfully',
	fileUploadSuccess: 'File uploaded successfully',
	fileUploadError: 'Error uploading file',
	checkInternetConnection: 'Check your internet connection!!',
	backToOnline: 'Back to online!',
	cloneMessage: 'Cloned Successfully',
	sendMessage: 'Sended message successfully',
	addMessage: 'Members added sucsessfully',
	patientsErrorMsg: 'Plesae select atleast one member',
	addVital: 'Vitals are added',
	submitted: 'Submitted Successfully',
	socketError: 'This messaging service is not available.',
}
//  New considered need to use this on all above places
export enum CommonMessages {
	ERROR_HANDLING_MESSAGE = 'Something went wrong',
	QUERY_CANCELLED_MESSAGE = 'Query was cancelled by React Query',
	CREATE_MESSAGE = 'Created Successfully',
	DELETE_MESSAGE = 'Deleted Successfully',
	UPDATE_MESSAGE = 'Updated Successfully',
	ASSIGNED_MESSAGE = 'Assigned Successfully',
	SENT_MESSAGE = 'The message has been sent successfully',
	FILE_UPLOAD_SUCCESS = 'File uploaded successfully',
	FILE_UPLOAD_ERROR = 'Error uploading file',
	CHECK_INTERNET_CONNECTION = 'Check your internet connection!!',
	BACK_TO_ONLINE = 'Back to online!',
	CLONE_MESSAGE = 'Cloned Successfully',
	SEND_MESSAGE = 'Sended message successfully',
	ADD_MESSAGE = 'Members added sucsessfully',
	PATIENTS_ERROR_MSG = 'Plesae select atleast one member',
	ADD_VITAL = 'Vitals are added',
	SUBMITTED = 'Submitted Successfully',
	SOCKET_ERROR = 'This messaging service is not available.',
	DOWNLOADING_ERROR = 'Downloading error',
	ERROR_DOWNLOADING_PDF = 'Error downloading PDF',
	CONVERSATION_MESSAGE = 'Pick a group from left menu and start your conversation',
	NO_DATA_FOUND = 'No data found',
}

export enum ServerErrorMessages {
	SERVER_DOWN = 'Sorry, it looks like the server is currently down. Please try again later.',
	NETWORK_ERROR = 'Network Error',
	NETWORK_ISSUE = 'ERR_NETWORK',
	INTERNAL_SERVER_ERROR = 'Internal Server Error',
	UNVERIFIED_EMAIL = 'Unverified Email',
	NO_PROVIDER_FOUND = 'Provider not found',
	USER_NOT_FOUND = 'User not found',
}

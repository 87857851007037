//  earlier used
export enum SCREEN_NAME_Previous {
	// MEMBER_PROFILE_VITALS = 'Members Profile Vitals',
	// MEMBER_PROFILE_PROFILE = 'Members Profile Profile',
	// MEMBER_PROFILE = 'Members Profile',
	// MEMBER_PROFILE_MEMBER_PROGRAMS = 'Members Profile Member Programs',
	// MEMBER_PROFILE_DEVICES = 'Members Profile Devices',
	// MEMBER_PROFILE_SESSIONS = 'Members Profile Sessions',
	// MEMBER_PROFILE_JOURNAL_NOTES = 'Members Profile Journal Notes',
	// MEMBER_PROFILE_TASKS = 'Members Profile Tasks',
	// MEMBER_PROFILE_CONDITIONS = 'Members Profile Conditions',
	// MEMBER_PROFILE_ALERTS = 'Members Profile Alerts',
	// MEMBER_PROFILE_GOALS = 'Members Profile Goals',
	// MEMBER_PROFILE_ASSESSMENT = 'Members Profile Assessment',
	// MEMBER_PROFILE_SCREENING = 'Members Profile Screening',
	// PARTNERS = 'Partners',
	// PROVIDERS = 'Providers',
	// PROVIDERS_PROFILE = 'Provider Profile',
	// MEMBERS = 'Members',
	// PARTNERS_PROFILE = 'Partner Profile',
	// PARTNERS_LOCATION = 'Location',
	// PARTNERS_POINT_OF_CONTACT = 'PointOfContact',
	// PARTNERS_SCHEDULING = 'Schedule',
	// PARTNERS_PROFILE_LOCATION = 'Partner Profile Locations',
	// PARTNERS_PROFILE_POINT_OF_CONTACT = 'Partner Profile Point Of Contact',
	// PARTNERS_PROFILE_SCHEDULING = 'Partner Profile Schedule',
	// PROGRAMS = 'Programs',
	// PROGRAM_PROFILE = 'Program Profile',
	// PROGRAM_PROFILE_PROGRAM_MEMBERS = 'Program Profile Program Members',
	// PROGRAM_PROFILE_VITALS = 'Program Profile Vitals',
	// PROGRAM_PROFILE_GOALS = 'Program Profile Goals',
	// PROGRAM_PROFILE_SESSIONS = 'Program Profile Sessions',
	// FEE_SCHEDULES = 'Fee Schedules',
	// REFERRALS = 'Referrals',
	REFERRALS_ID = 'Referrals Id', // check
	PROVIDER_PROFILE = 'Provider Profile', // check
	// CALENDAR = 'Calendar',
	// CHATS = 'Chats',
	// CLAIMS = 'Claims',
	CLAIMS_DETAIL = 'Claims Detail', // check
	// CLAIMS_INFORMATION = 'Claims Information',
	// CLIENTS = 'Clients',
	// REPORT = 'Report',
	// SUMMARY_REPORT = 'Summary Report',
	// AUDITS = 'Audits',
	EPI_DASHBOARD = 'Epidemiologist Dashboard', // not in used
	// EPI_MEMBERS = 'Epidemiologist Members',
	// EPI_PARTNERS = 'Epidemiologist Partners',
	DASHBOARD = 'Dashboard',
	// PROGRAM_MANAGER_DASHBOARD = 'ProgramManager Dashboard',
	// EPIDEMOLOGIST_DASHBOARD = 'Epidemiologist Dashboard',
	PROVIDER_DASHBOARD = 'Provider Dashboard', // not in use
	// ROLES = 'Roles',
	// ROLE_PROFILE = 'Role Profile',
	SETTINGS = 'Settings', // not in use
	// SETTINGS_LAYOUT = 'Settings Layout',

	GLOBAL_CODES = 'Global Codes', // not in use
	GLOBAL_CODE_CATEGORY = 'Global Code Category', // not in use
	GLOBAL_SPECIALITIES = 'Global Specialities', // not in use
	GLOBAL_CONDITIONS = 'Global Conditions', // not in use
	MODULES = 'Modules', // not in use

	// GLOBAL_PROGRAMS = 'Global Programs',
	// GLOBAL_SESSIONS = 'Global Sessions',
	// GROUPS = 'Groups',
	// ADMIN_DASHBOARD = 'Admin Dashboard',

	ADD_GLOBLE_SESSIONS = 'Add Session', // check
	// SCREENING_TEMPLATE = 'Screening Template',
	// TEMPLATE_INFORMATION = 'Template Information',
	// SCREENING = 'Screening',
	// NOTIFICATIONS = 'Notifications',
	// MEMBER_FILE_UPLOAD = 'File upload',
	// SESSIONS = 'Session',
	// SCREENING_TEMPLATE_DASHBOARD = 'Screening Template Dashboard',
	// GLOBAL_PROGRAM_PROFILE = 'Global Program Profile',
	// QUESTIONNARIE_VIEW = 'Questionnaire View',
	// GLOBAL_PROGRAM_GOALS = 'Gloabl Program Profile Goals',
	// GLOBAL_PROGRAM_VITALS = 'Global Program Profile Vitals',
	// GLOBAL_PROGRAM_SESSIONS = 'Global Program Profile Sessions',
	// SCREENING_LIBRARY = 'Screening Library',
	// MEMBER_PROFILE_SESSION_INFORMATION = 'Members Profile Sessions Information',
	// MESSAGES = 'Messages',
}

// export enum SCREEN_NAME {
// 	MEMBER_PROFILE_VITALS = 'Members Profile Vitals',
// 	MEMBER_PROFILE_PROFILE = 'Members Profile Profile',
// 	MEMBER_PROFILE = 'Members Profile',
// 	MEMBER_PROFILE_MEMBER_PROGRAMS = 'Members Profile Member Programs',
// 	MEMBER_PROFILE_DEVICES = 'Members Profile Devices',
// 	MEMBER_PROFILE_SESSIONS = 'Members Profile Sessions',
// 	MEMBER_PROFILE_JOURNAL_NOTES = 'Members Profile Journal Notes',
// 	MEMBER_PROFILE_TASKS = 'Members Profile Tasks',
// 	MEMBER_PROFILE_CONDITIONS = 'Members Profile Conditions',
// 	MEMBER_PROFILE_ALERTS = 'Members Profile Alerts',
// 	MEMBER_PROFILE_GOALS = 'Members Profile Goals',
// 	MEMBER_PROFILE_ASSESSMENT = 'Members Profile Assessment',
// 	MEMBER_PROFILE_SCREENING = 'Members Profile Screening',
// 	PARTNERS = 'Partners',
// 	PROVIDERS = 'Providers',
// 	PROVIDERS_PROFILE = 'Provider Profile',
// 	MEMBERS = 'Members',
// 	PARTNERS_PROFILE = 'Partner Profile',
// 	PARTNERS_LOCATION = 'Location',
// 	PARTNERS_POINT_OF_CONTACT = 'PointOfContact',
// 	PARTNERS_SCHEDULING = 'Schedule',
// 	PARTNERS_PROFILE_LOCATION = 'Partner Profile Locations',
// 	PARTNERS_PROFILE_POINT_OF_CONTACT = 'Partner Profile Point Of Contact',
// 	PARTNERS_PROFILE_SCHEDULING = 'Partner Profile Schedule',
// 	PROGRAMS = 'Programs',
// 	PROGRAM_PROFILE = 'Program Profile',
// 	PROGRAM_PROFILE_PROGRAM_MEMBERS = 'Program Profile Program Members',
// 	PROGRAM_PROFILE_VITALS = 'Program Profile Vitals',
// 	PROGRAM_PROFILE_GOALS = 'Program Profile Goals',
// 	PROGRAM_PROFILE_SESSIONS = 'Program Profile Sessions',
// 	FEE_SCHEDULES = 'Fee Schedules',
// 	REFERRALS = 'Referrals',
// 	REFERRALS_ID = 'Referrals Id',
// 	PROVIDER_PROFILE = 'Provider Profile',
// 	CALENDAR = 'Calendar',
// 	CHATS = 'Chats',
// 	CLAIMS = 'Claims',
// 	CLAIMS_DETAIL = 'Claims Detail',
// 	CLAIMS_INFORMATION = 'Claims Information',
// 	CLIENTS = 'Clients',
// 	REPORT = 'Report',
// 	SUMMARY_REPORT = 'Summary Report',
// 	AUDITS = 'Audits',
// 	EPI_DASHBOARD = 'Epidemiologist Dashboard',
// 	EPI_MEMBERS = 'Epidemiologist Members',
// 	EPI_PARTNERS = 'Epidemiologist Partners',
// 	DASHBOARD = 'Dashboard',
// 	ROLES = 'Roles',
// 	ROLE_PROFILE = 'Role Profile',
// 	SETTINGS = 'Settings',
// 	SETTINGS_LAYOUT = 'Settings Layout',
// 	GLOBAL_CODES = 'Global Codes',
// 	GLOBAL_CODE_CATEGORY = 'Global Code Category',
// 	GLOBAL_SPECIALITIES = 'Global Specialities',
// 	GLOBAL_CONDITIONS = 'Global Conditions',
// 	MODULES = 'Modules',
// 	GLOBAL_PROGRAMS = 'Global Programs',
// 	GLOBAL_SESSIONS = 'Global Sessions',
// 	GROUPS = 'Groups',
// 	PROGRAM_MANAGER_DASHBOARD = 'ProgramManager Dashboard',
// 	ADMIN_DASHBOARD = 'Admin Dashboard',
// 	EPIDEMOLOGIST_DASHBOARD = 'Epidemiologist Dashboard',
// 	PROVIDER_DASHBOARD = 'Provider Dashboard',
// 	SCREENING_TEMPLATE = 'Screening Template',
// 	ADD_GLOBLE_SESSIONS = 'Add Session',
// 	TEMPLATE_INFORMATION = 'Template Information',
// 	SCREENING = 'Screening',
// 	NOTIFICATIONS = 'Notifications',
// 	MEMBER_FILE_UPLOAD = 'File upload',
// 	SESSIONS = 'Session',
// 	SCREENING_TEMPLATE_DASHBOARD = 'Screening Template Dashboard',
// 	GLOBAL_PROGRAM_PROFILE = 'Global Program Profile',
// 	QUESTIONNARIE_VIEW = 'Questionnaire View',
// 	GLOBAL_PROGRAM_GOALS = 'Gloabl Program Profile Goals',
// 	GLOBAL_PROGRAM_VITALS = 'Global Program Profile Vitals',
// 	GLOBAL_PROGRAM_SESSIONS = 'Global Program Profile Sessions',
// 	SCREENING_LIBRARY = 'Screening Library',
// 	MEMBER_PROFILE_SESSION_INFORMATION = 'Members Profile Sessions Information',
// 	MESSAGES = 'Messages',
// }
export enum SCREEN_NAME {
	ADMIN_DASHBOARD = 'dashboard_admin',

	PROGRAM_MANAGER_DASHBOARD = 'dashboard_program_manager',

	EPIDEMOLOGIST_DASHBOARD = 'dashboard_epidemiologist',
	EPI_MEMBERS = 'epidemiologist_members',
	EPI_MEMBERS_DETAIL = 'epidemiologist_members/:id',
	EPI_PARTNERS = 'epidemiologist_partners',
	EPI_REPORT_MEMBERS = 'report-view-member/:id',
	AUDITS = 'audits',
	CLIENTS = 'clients',

	PROVIDERS = 'providers',
	PROVIDER_PROFILE = 'provider-profile',

	PARTNERS = 'partners',
	PARTNERS_PROFILE = 'partner-profile/:id',
	PARTNERS_PROFILE_LOCATION = 'locations',
	PARTNERS_PROFILE_POINT_OF_CONTACT = 'pointOfContacts',
	PARTNERS_PROFILE_SCHEDULING = 'schedule',

	REFERRALS = 'referrals',
	REFERRALS_DETAILS = 'referrals/:id', // check

	FEE_SCHEDULES = 'fee-schedules',

	MEMBERS = 'members',
	MEMBER_PROFILE = 'member-profile/:id',

	MEMBER_PROFILE_VITALS = 'vitals',
	MEMBER_PROFILE_ALERTS = 'alerts',
	MEMBER_PROFILE_MEMBER_PROGRAMS = 'member-programs',
	MEMBER_PROFILE_DEVICES = 'devices',
	MEMBER_PROFILE_GOALS = 'goals',
	MEMBER_PROFILE_CONDITIONS = 'conditions',
	MEMBER_PROFILE_SESSIONS = 'sessions',
	MEMBER_PROFILE_JOURNAL_NOTES = 'journal-notes',
	MEMBER_PROFILE_SCREENING = 'screening',
	MEMBER_PROFILE_TASKS = 'tasks',
	MEMBER_PROFILE_PROFILE = 'profile',
	MEMBER_PROFILE_ASSESSMENT = 'assessment',
	MEMBER_PROFILE_SESSION_INFORMATION = 'session-view/:sessionId',
	MEMBER_FILE_UPLOAD = 'fileUpload',
	MEMBER_PROFILE_CALENDARS = 'calendars',

	PROGRAMS = 'programs',
	PROGRAM_PROFILE = 'program-profile/:id',
	// PROGRAM_PROFILE_PROGRAM_MEMBERS = 'members',
	PROGRAM_PROFILE_PROGRAM_MEMBERS = 'program-members',
	PROGRAM_PROFILE_VITALS = 'program-vitals',
	PROGRAM_PROFILE_GOALS = 'program-goals',
	PROGRAM_PROFILE_SESSIONS = 'program-sessions',

	QUESTIONNARIE_VIEW = 'sessions-questions/:sessionPatientId',

	REPORT = 'report',

	CLAIMS = 'claims',
	CLAIMS_INFORMATION = 'claims/:id',
	CLAIMS_DETAIL = 'Claims Detail',

	CALENDAR = 'calendar',
	CALENDAR_SCHEDULE = 'calendar/calendar-schedule',

	GROUPS = 'groups',

	CHATS = 'chats',

	SUMMARY_REPORT = 'summary-report',

	ROLES = 'roles',
	ROLE_PROFILE = 'role-profile/:id',

	SCREENING_TEMPLATE = 'templates',
	TEMPLATE_INFORMATION = 'template-information',
	SCREENING_TEMPLATE_DASHBOARD = 'template-client-view/:id',
	SCREENING = 'question',
	SCREENING_LIBRARY = 'screening-library',

	MESSAGES = 'messages',

	NOTIFICATIONS = 'notifications',

	MY_PROFILE = 'myProfile', // check

	SETTINGS_LAYOUT = 'settings',
	GLOBAL_PROGRAMS = 'globalPrograms',
	GLOBAL_PROGRAM_PROFILE = 'global-program-profile/:id',
	GLOBAL_PROGRAM_GOALS = 'global-program-goals',
	GLOBAL_PROGRAM_SESSIONS = 'global-program-sessions',
	GLOBAL_PROGRAM_VITALS = 'global-program-vitals',
	GLOBAL_SESSIONS = 'globalSessions',

	SESSIONS = 'session',

	TASK_MANAGEMENT = 'task-management',
	TASK_TRACKING = 'task-tracking',
	TASK_REPORT = 'taskreport',
	DASHBOARD_TASK_MANAGEMENT = 'dashboard-task-management',
	DASHBOARD_TASK_MANAGEMENT_TASK_TRACKING = 'dashboard-task-management/task-tracking',
	TASK_MANAGEMENT_TASK_LISTING = 'task-management/task-listing',
	DASHBOARD_TASK_MANAGEMENT_TASK_LISTING = 'dashboard-task-management/task-listing',
	TASK_MANAGEMENT_TASK_LIBRARY = 'task-management/task-library',
	TASK_MANAGEMENT_TASK_FOLLOW_UP = 'task-management/task-follow-ups-listing',
	DASHBOARD_TASK_MANAGEMENT_TASK_FOLLOW_UP = 'dashboard-task-management/task-follow-ups-listing',
	DASHBOARD_TASK_MANAGEMENT_CALL_LISTING = 'dashboard-task-management/call-listing',
	TASK_MANAGEMENT_TASK_DETAIL = 'task-management/task-detail',
	DASHBOARD_TASK_MANAGEMENT_TASK_DETAIL = 'dashboard-task-management/task-detail',
	TASK_REPORT_TASK_DETAIL = 'taskreport/task-detail',
	TASK_REPORT_TASK_TRACKING = 'taskreport/task-tracking',
	DASHBOARD_TASK_MANAGEMENT_CALL_DETAIL = 'dashboard-task-management/call-details',
	// smbp-memberProfile
	// COMMUNITY BASED PARTNERS
	COMMUNITY_BASED_PARTNERS = 'smbp_members',
	COMMUNITY_BASED_REPORTS = 'smbp_reports',
	COMMUNITY_BASED_PM_MEMBERS = 'pm-members',
	COMMUNITY_BASED_DASHBOARD = 'smbp_dashboard',
	COMMUNITY_BASED_MEMBER_PROFILE = 'smbp_memberProfile/:id',
	COMMUNITY_BASED_MEMBER_PROFILE_VITALS = 'non-healthcare-vitals',
	COMMUNITY_BASED_MEMBER_PROFILE_DEVICES = 'non-healthcare-devices',
}

export enum COLUMN_NAME {
	ACTION = 'actions',
	ACTIVE = 'active',
	STATUS = 'status',
	QUESTIONNARIE = 'questionnaire',
	CPT = 'cpt',
}

export enum CHILD_SCREEN_NAME {
	VIEW_CPT = 'viewcpt',
	GLOBAL_VIEW_CPT = 'globalviewcpt',
	VIEW_QUESTIONNARIE = 'viewquestionnaire',
	GLOBAL_VIEW_QUESTIONNARIE = 'globalViewQuestionnaire',
	VIEW_GLOBAL_SESSION_QUESTIONNARIE = 'viewGlobalSessionQuestionnaire',
	EDIT_QUESTIONNARIE = 'editquestionnaire',
	MEMBER_GOALS = 'goals',
	CARE_TEAM = 'careteam',
	STAR_PARTNERS = 'starpartners',
	GENERAL_TASKS = 'generaltasks',
	PROGRAM_TASKS = 'programtasks',
	PARTNERS = 'partners',
}

export enum GRID_SECTION_DETAIL_NAME {
	LOGIN_GRID = 'logingrid',
	MEMBER_GRID = 'membergrid',
	PARTNER_GRID = 'partnergrid',
	PROGRAM_GRID = 'programgrid',
	PROVIDER_GRID = 'providergrid',
	REPORT_GRID = 'reportgrid',
	VITAL_GRID = 'vitalgrid',
	GRID = 'grid',
}
export enum GLOBAL_CODE {
	LEFT = 'Left',
}

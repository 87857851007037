import _ from 'lodash'
import { CancelTokenSource } from 'axios'
import axios from 'services/axios'
import { ENDPOINTS } from 'const/endpoints'
import { commonMessages } from 'utils/messages'

export async function getPermission(
	params: Partial<{ client: string | undefined; providerRole: any }>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = await axios.get<any>(ENDPOINTS.PERMISSION, {
		params: filteredParams,
	})
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel('Query was cancelled by React Query')
	}

	return promise
}

/**
 * * GET request for partners client
 * Gives the client of the particular partner
 */
export function getServicesClients(
	params: {
		limit: number | undefined
		page?: number | string
		search?: string
		sort?: string
		client?: string | undefined
	},
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get(ENDPOINTS.SERVICES_CLIENTS, {
		params: filteredParams,
	})

	//@ts-ignore
	// Add cancel method to the promise
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}
